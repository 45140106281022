const {
  makeApiRoot,
  makeIASConnectRoot,
  makeSIAConnectRoot,
  makeCoreRoot,
  makeSearchRoot,
  makeKeycloakUrl,
  makeSimulcastUrl,
  makeHyperlaneUrl,
  makeBookSheetUrl,
  makeIASTermsUrl,
  makeSIATermsUrl,
  makeAnylineLicense,
  REAL_TIME_URL,
  PORT,
  makeDarwinStratosConfig,
} = require('./configHelpers');

const baseConfig = {
  port: PORT,
  localization: 'en-us',
  runbuggyConfig: {
    scriptSrc: 'https://apps.runbuggy.com/runbuggy/web-components/production/runbuggy.min.js',
    clientId: '26f29704-c2d8-4bd8-a82f-e8ec764fc122'
  },
  carblyConfig: {
    baseUrl: 'https://web.getcarbly.com/vehicles/new?pid=darwin'
  }
}

const config = {
  'aane365.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '544',
    name: 'Auto Auction of New England 365',
    shortName: 'AANE365',
    bucket: 'aane365.iasmarketplace.com',
    apiRoot: makeApiRoot('awg'),
    iasConnectRoot: makeIASConnectRoot('signin-aane365'),
    searchRoot: makeSearchRoot('ias-us-aane365'),
    coreRoot: makeCoreRoot('awg'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    liveSimulcastUrl: makeSimulcastUrl('aane365-mp'),
    hyperlaneUrl: makeHyperlaneUrl('aane365-mp'),
    booksheetUrl: makeBookSheetUrl('aane365-mp'),
    termsLink: makeIASTermsUrl('aane365'),
    // anylineLicenseKey: makeAnylineLicense('aane365.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    odometerType: 'Miles',
    gaId: 'G-Z2NV7MK93E',
  },
  /* 7-3-2024 -- Removed per MP-1377 
  'avdaa.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '519',
    name: 'All Valley Dealers Auto Auction',
    shortName: 'AVDAA',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'avdaa.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-avdaa'),
    booksheetUrl: makeBookSheetUrl('avdaa-mp'),
    searchRoot: makeSearchRoot('ias-us-avdaa'),
    liveSimulcastUrl: makeSimulcastUrl('avdaa-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('avdaa-mp'),
    termsLink: makeIASTermsUrl('avdaa'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-0JVNCNMJD4',
  }, 
    7-3-2024 -- Removed per MP-1377 
  'bocl.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '585',
    name: 'Bid On Cars Lancaster',
    shortName: 'BOCL',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'bocl.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-bocl'),
    booksheetUrl: makeBookSheetUrl('bocl-mp'),
    searchRoot: makeSearchRoot('ias-us-bocl'),
    liveSimulcastUrl: makeSimulcastUrl('bocl-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('bocl-mp'),
    termsLink: makeIASTermsUrl('bocl'),
    enableIfSales: false,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-NXJ5VZ4L2D',
  }, 
    7-3-2024 -- Removed per MP-1377 
  'carketa.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '552',
    name: 'Carketa',
    shortName: 'Carketa',
    apiRoot: makeApiRoot('mpapi'),
    iasConnectRoot: makeIASConnectRoot('signin-carketa'),
    bucket: 'carketa.iasmarketplace.com',
    booksheetUrl: makeBookSheetUrl('carketa-mp'),
    searchRoot: makeSearchRoot('ias-us-carketa'),
    liveSimulcastUrl: makeSimulcastUrl('carketa-mp'),
    keycloakUrl: makeKeycloakUrl('Carketa-MP'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('carketa-mp'),
    termsLink: makeIASTermsUrl('carketa'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    mastheadOpacity: 0,
    crispId: '4a74405f-1ddf-440f-a30d-e835d2ca5644',
    gaId: 'G-VQL32VNZJR',
  }, */
  'dealernet.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '425',
    name: 'Dealernet',
    shortName: 'Dealernet',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'dealernet.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-dealernet'),
    booksheetUrl: makeBookSheetUrl('dealernet-mp'),
    searchRoot: makeSearchRoot('ias-us-dealernet'),
    liveSimulcastUrl: makeSimulcastUrl('dealernet-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('dealernet'), // note
    termsLink: makeIASTermsUrl('dealernet'),
    // anylineLicenseKey: makeAnylineLicense('dealernet.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    enableAutoIPacket: true,
    gaId: 'G-CSPYEPKPPK',
  },
  'frontrange.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '557',
    name: 'Front Range Auto Auction',
    shortName: 'Front Range',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'frontrange.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-frontrange'),
    booksheetUrl: makeBookSheetUrl('fraa-mp'),
    searchRoot: makeSearchRoot('ias-us-frontrange'),
    liveSimulcastUrl: makeSimulcastUrl('fraa-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('fraa-mp'),
    termsLink: makeIASTermsUrl('frontrange'),
    anylineLicenseKey: makeAnylineLicense('frontrange.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-7R76TQCHGF',
    runbuggyConfig: {
      scriptSrc: 'https://ng-staging.runbuggy.com/staging/web-components/staging/runbuggy.min.js',
      clientId: 'ddf553b3-acb7-4d87-83db-388faa3a0a8f'
    },
  },
  'equipment.holman.com': {
    ...baseConfig,
    marketplaceId: '540',
    name: 'Holman Equipment Direct',
    shortName: 'Holman',
    iasConnectRoot: makeIASConnectRoot('signin-holman'),
    apiRoot: makeApiRoot('mpapi'),
    liveSimulcastUrl: makeSimulcastUrl('autoconnect'),
    booksheetUrl: makeBookSheetUrl('autoconnect'),
    keycloakUrl: makeKeycloakUrl('Holman-Equipment-Direct'),
    coreRoot: makeCoreRoot('mpapi'),
    bucket: 'holman.iasmarketplace.com',
    hyperlaneUrl: makeHyperlaneUrl('ari'),
    searchRoot: makeSearchRoot('ias-us-holman'),
    termsLink: null,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: false,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    hideSiaSupportWidget: true,
    hideSiaChatWidget: true,
    alertTypes: [1008, 1029, 1006, 1016], // holman specific
    enableDynamicAttributes: true, // holman specific
    agreeToTermsAtLogin: true, // holman specific
    hideSinglePaymentOption: true, // holman specific
    hideBidderInBidHistory: true, // holman specific
    disableSellerActivity: true, // holman specific
    enableAddressValidation: true, // holman specific
    enableHighAmountConfirm: true, // holman specific
    bidButtonAltColor: '#c0392b', // holman specific
    appIOS:
      'https://apps.apple.com/us/app/holman-equipment-direct/id1581597740',
    appAndroid: 
      'https://play.google.com/store/apps/details?id=com.arifleet.equipmentdirect',
    realTimeUrl: REAL_TIME_URL,
    gaId: 'G-SM8NFE1CC4',
  },
  'ice.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '385',
    name: 'Indianapolis Car Exchange',
    shortName: 'ICE',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'ice.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-ice'),
    booksheetUrl: makeBookSheetUrl('ice-mp'),
    searchRoot: makeSearchRoot('ias-us-ice'),
    liveSimulcastUrl: makeSimulcastUrl('ice-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('ice-mp'),
    termsLink: makeIASTermsUrl('ice'),
    // anylineLicenseKey: makeAnylineLicense('ice.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-N1YSB8730N',
  },
  'lafontaine.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '568',
    name: 'LaFontaine Automotive Group',
    shortName: 'LAG',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'lafontaine.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-lafontaine'),
    booksheetUrl: makeBookSheetUrl('lafontaine-mp'),
    searchRoot: makeSearchRoot('ias-us-lafontaine'),
    liveSimulcastUrl: makeSimulcastUrl('lafontaine-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('lafontaine-mp'),
    termsLink: makeIASTermsUrl('lafontaine'),
    // anylineLicenseKey: makeAnylineLicense('lafontaine.iasmarketplace.com'),
    enableIfSales: false,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-WCWC0TP9SK',
  },
  /* 7-3-2024 -- Removed per MP-1377 
  'lsremarketing.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '581',
    name: 'LS Remarketing Auto Auction',
    shortName: 'LSRAA',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'lsremarketing.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-lsremarketing'),
    booksheetUrl: makeBookSheetUrl('lsremarketing-mp'),
    searchRoot: makeSearchRoot('ias-us-lsremarketing'),
    liveSimulcastUrl: makeSimulcastUrl('lsremarketing-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('lsremarketing-mp'),
    termsLink: makeIASTermsUrl('lsremarketing'),
    enableIfSales: false,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    mastheadOpacity: 0,
    gaId: 'G-CX109966YS',
  }, */

  'maa.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '527',
    name: 'Moberly Auto Auction',
    shortName: 'MAA',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'maa.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-maa'),
    booksheetUrl: makeBookSheetUrl('maa-mp'),
    searchRoot: makeSearchRoot('ias-us-maa'),
    liveSimulcastUrl: makeSimulcastUrl('moberly-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('moberly-mp'),
    termsLink: makeIASTermsUrl('maa'),
    // anylineLicenseKey: makeAnylineLicense('maa.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-P75GESFLVL',
  },
  'okaex.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '524',
    name: 'Oklahoma Auto Exchange',
    shortName: 'OKAEX',
    apiRoot: makeApiRoot('awg'),
    bucket: 'okaex.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-okaex'),
    booksheetUrl: makeBookSheetUrl('okaex-mp'),
    searchRoot: makeSearchRoot('ias-us-okaex'),
    liveSimulcastUrl: makeSimulcastUrl('okaex-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('awg'),
    hyperlaneUrl: makeHyperlaneUrl('okaex-mp'),
    termsLink: makeIASTermsUrl('okaex'),
    // anylineLicenseKey: makeAnylineLicense('okaex.iasmarketplace.com'),
    enableIfSales: true,
    enableArbitration: true,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-GLR5QSNKLB',
  },
  /* 7-3-2024 -- Removed per MP-1377 
  'statewide.iasmarketplace.com': {
    ...baseConfig,
    marketplaceId: '529',
    name: 'Statewide Auto Auction',
    shortName: 'Statewide',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'statewide.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-statewide'),
    booksheetUrl: makeBookSheetUrl('statewide-mp'),
    searchRoot: makeSearchRoot('ias-us-statewide'),
    liveSimulcastUrl: makeSimulcastUrl('statewide-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('statewide-mp'),
    termsLink: makeIASTermsUrl('statewide'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-SZEM7PGLV9',
  },
    7-3-2024 -- Removed per MP-1377 
  'www.premierremarketplace.com': {
    ...baseConfig,
    marketplaceId: '535',
    name: 'Premier Automotive Group',
    shortName: 'Premier',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'premier.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-premier'),
    booksheetUrl: makeBookSheetUrl('ptg-mp'),
    searchRoot: makeSearchRoot('ias-us-premier'),
    liveSimulcastUrl: makeSimulcastUrl('ptg-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('ptg-mp'),
    termsLink: makeIASTermsUrl('premier'),
    bidLabel: 'Bid',
    ennableBidLabel: true,
    enableIfSales: false,
    enableArbitration: false,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    homeLabel: 'ReMarketplace',
    gaId: 'G-EZN3PCSGBT',
  }, */
  'www.stealthautoauctions.com': {
    ...baseConfig,
    marketplaceId: '583',
    name: 'Stealth Auto Auction',
    shortName: 'Stealth',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'stealth.iasmarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-stealth'),
    booksheetUrl: makeBookSheetUrl('stealth-mp'),
    searchRoot: makeSearchRoot('ias-us-stealth'),
    liveSimulcastUrl: makeSimulcastUrl('stealth-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('stealth-mp'),
    termsLink: makeIASTermsUrl('stealth'),
    enableIfSales: false,
    enableArbitration: true,
    enableOffersTab: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-ZLWVN4FNTX',
  },
  'mpdemo.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '587',
    name: 'Your Marketplace',
    shortName: 'Your MP',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'yourmarketplace.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-mpdemo'),
    booksheetUrl: makeBookSheetUrl('mpdemo'),
    searchRoot: makeSearchRoot('ias-us-yourmarketplace'),
    liveSimulcastUrl: makeSimulcastUrl('mpdemo'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('mpdemo'),
    termsLink: makeSIATermsUrl('yourmarketplace'),
    anylineLicenseKey: makeAnylineLicense('mpdemo.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-MLQHYQQ8Y4',
    runbuggyConfig: {
      scriptSrc: 'https://ng-staging.runbuggy.com/staging/web-components/staging/runbuggy.min.js',
      clientId: 'ddf553b3-acb7-4d87-83db-388faa3a0a8f'
    },
  },
  'lorensen.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '588',
    name: 'Lorensen',
    shortName: 'Lorensen',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'lorensen.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-lorensen'),
    booksheetUrl: makeBookSheetUrl('lorensen'),
    searchRoot: makeSearchRoot('ias-us-lorensen'),
    liveSimulcastUrl: makeSimulcastUrl('lorensen'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('lorensen'),
    termsLink: makeSIATermsUrl('lorensen'),
    // anylineLicenseKey: makeAnylineLicense('lorensen.siamarketplace.com'),
    // stratosConfig: makeDarwinStratosConfig('588'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-FFNN7GP4BN',
  },
  'hertz.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '589',
    name: 'Hertz',
    shortName: 'Hertz',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'hertz.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-hertz'),
    booksheetUrl: makeBookSheetUrl('hertz'),
    searchRoot: makeSearchRoot('ias-us-hertz'),
    liveSimulcastUrl: makeSimulcastUrl('hertz'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('hertz'),
    termsLink: makeSIATermsUrl('hertz'),
    anylineLicenseKey: makeAnylineLicense('hertz.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: null, 
  },
  'hondaofwatertown.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '590',
    name: 'Hondaofwatertown',
    shortName: 'Hondaofwatertown',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'hondaofwatertown.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-hondaofwatertown'),
    booksheetUrl: makeBookSheetUrl('hondaofwatertown'),
    searchRoot: makeSearchRoot('ias-us-hondaofwatertown'),
    liveSimulcastUrl: makeSimulcastUrl('hondaofwatertown'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('hondaofwatertown'),
    termsLink: makeSIATermsUrl('hondaofwatertown'),
    // anylineLicenseKey: makeAnylineLicense('hondaofwatertown.siamarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-J7B6W421HQ',
  },
  'wallys.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '591',
    name: 'Wallys',
    shortName: 'Wallys',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'wallys.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-wallys'),
    searchRoot: makeSearchRoot('ias-us-wallys'),
    liveSimulcastUrl: makeSimulcastUrl('wallys'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('wallys'),
    termsLink: makeSIATermsUrl('wallys'),
    // anylineLicenseKey: makeAnylineLicense('wallys.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-RN8VSMN1N1',
  },
  'gcaa.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '482',
    name: 'Gulf Coast Auto Auction',
    shortName: 'GCAA',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'gcaa.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-gcaa'),
    searchRoot: makeSearchRoot('ias-us-gcaa'),
    liveSimulcastUrl: makeSimulcastUrl('gcaa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('gcaa'),
    termsLink: makeSIATermsUrl('gcaa'),
    anylineLicenseKey: makeAnylineLicense('gcaa.siamarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-K571MBGD7E',
  },
  'darwin.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '592',
    name: 'Darwin',
    shortName: 'Darwin',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'darwin.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-darwin'),
    searchRoot: makeSearchRoot('ias-us-darwin'),
    liveSimulcastUrl: makeSimulcastUrl('darwin'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('darwin'),
    termsLink: makeSIATermsUrl('darwin'),
    anylineLicenseKey: makeAnylineLicense('darwin.siamarketplace.com'),
    stratosConfig: makeDarwinStratosConfig('592'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-NW2E7H0WNN',
  },
  'lcaa.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '594',
    name: 'Licking County Auto Auction',
    shortName: 'LCAA',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'lcaa.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-lcaa'),
    searchRoot: makeSearchRoot('ias-us-lcaa'),
    liveSimulcastUrl: makeSimulcastUrl('lcaa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('lcaa'),
    termsLink: makeSIATermsUrl('lcaa'),
    anylineLicenseKey: makeAnylineLicense('lcaa.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-42SHE2KMNG',
  },
  'launch.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '593',
    name: 'Launch',
    shortName: 'Launch',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'launch.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-launch'),
    searchRoot: makeSearchRoot('ias-us-launch'),
    liveSimulcastUrl: makeSimulcastUrl('launch'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('launch'),
    termsLink: makeSIATermsUrl('launch'),
    anylineLicenseKey: makeAnylineLicense('launch.siamarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    hideAACredsUpdate: true,
    enableBuyerTitles: true,
    enableSellerTitles: true,
    gaId: 'G-QEW78Q5DZZ', // 'sia-marketplace' project (Marketplace Connect app)
  },
  'keffer.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '595',
    name: 'Keffer',
    shortName: 'Keffer',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'keffer.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-keffer'),
    searchRoot: makeSearchRoot('ias-us-keffer'),
    liveSimulcastUrl: makeSimulcastUrl('keffer'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('keffer'),
    termsLink: makeSIATermsUrl('keffer'),
    anylineLicenseKey: makeAnylineLicense('keffer.siamarketplace.com'),
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-5R1MX74KKP', 
  },
  'abg.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '596',
    name: 'Avis',
    shortName: 'Avis',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'abg.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-abg'),
    searchRoot: makeSearchRoot('ias-us-abg'),
    liveSimulcastUrl: makeSimulcastUrl('abg'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('abg'),
    termsLink: makeSIATermsUrl('abg'),
    anylineLicenseKey: makeAnylineLicense('abg.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-JXPG3J0NX8', 
  },
  'rlships.siamarketplace.com': {
    ...baseConfig,
    marketplaceId: '597',
    name: 'Royalty Logistics',
    shortName: 'RLSHIPS',
    apiRoot: makeApiRoot('mpapi'),
    bucket: 'rlships.siamarketplace.com',
    iasConnectRoot: makeSIAConnectRoot('signin-rlships'),
    searchRoot: makeSearchRoot('ias-us-rlships'),
    liveSimulcastUrl: makeSimulcastUrl('rlships'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace'),
    coreRoot: makeCoreRoot('mpapi'),
    hyperlaneUrl: makeHyperlaneUrl('rlships'),
    termsLink: makeSIATermsUrl('rlships'),
    anylineLicenseKey: makeAnylineLicense('rlships.siamarketplace.com'),
    enableBuyerSellerArbitration: true,
    enableIfSales: true,
    enableArbitration: false,
    enableOffersTab: true,
    enableBuyerTitles: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: 'G-W7B2WE9CHF', 
  },
  default: {
    marketplaceId: '0',
  },
};

module.exports = config;
