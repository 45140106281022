
/**
 * Initial MP Config values
 * - **Note:** Commented items are optional or customer-specific
 */
export const mpConfigInitialState = {
    port: 3000,
    localization: "en-us",
    agreeToTermsAtLogin: false,
    marketplaceId: "",
    name: "",
    shortName: "",
    iasConnectRoot: "", // "https:// signin-example.xyzmarketplace.com",
    apiRoot: "",        // "https://mpapi.example.com/web/simulcast/api/services/",
    liveSimulcastUrl: "",
    booksheetUrl: "",
    keycloakUrl: "",    // "https://keycloak.xyzmarketplace.com/auth/admin/realms/Specific-Realm",
    coreRoot: "",       //"https://mpapi.example.com/web/simulcast/core/api/services/",
    bucket: "",
    hyperlaneUrl: "",   // "https://xyz.example.com/web/awgsimulcast/hyperlane.aspx",
    searchRoot: "",     // "https://mpname.exampledomain.com/index-us-example-listing/",
    termsLink: null,
    enableArbitration: false,
    enableBuyerSellerArbitration: false,
    enableBuyerTitles: false,
    enableDynamicAttributes: false,
    enableIfSales: false,
    enableOffersTab: false,
    enableSellerTitles: false,
    hideAACredsUpdate: true,
    gaId: null,          // "G-EX4MP13"

    // anylineLicenseKey: "",
    // runbuggyConfig: {
    //   scriptSrc: "",
    //   clientId: ""
    // },
    // carblyConfig: {
    //   baseUrl: "https://web.getcarbly.com/vehicles/new?pid=xxxxxxxxxx"
    // },
    // stratosConfig: {
    //     dealerGuid: "xxxxx-xxx-xx-xxxxx",
    //     scriptSrc: ""
    // },
    // hideSiaChatWidget: true,
    // hideSiaSupportWidget: true,
    // hideSinglePaymentOption: true,
    // hideBidderInBidHistory: true,
    // realTimeUrl: "", //"https://mpapi.example.com/web/realtime/notificationHub",
    // alertTypes: [1008, 1029, 1006, 1016],
    // disableSellerActivity: true,
    // enableAddressValidation: true,
    // enableHighAmountConfirm: true,
    // bidButtonAltColor: "#c0392b",
    // appIOS: "https://apps.apple.com/us/app/app-name/id123456789",
    // appAndroid: "https://play.google.com/store/apps/details?id=com.example.bundleid",
  }