import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Button from '../ui/buttons';
import { CYAN_01, GREY_05, BLACK_02, WHITE_01, GREY_04, BLUE_01, GREEN_01 } from '../../constants/colors';
import { init as anylineInit, getAnylineWorker } from '@anyline/anyline-js';
import { getConfig, makeAssetLink } from '../../../../utils/helpers';
import {anylineStates} from '../../../../models'

const styles = {
    customStyles: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        zIndex: 125,
        position: 'absolute',
        borderRadius: '8px',
        border: 'none',
        boxShadow:
          '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        zIndex: 99,
      },
    },
  };

/**
 * 
 * @param {{isOpen:boolean,onBack:()=>void,onClose:()=>void,onVinScan:function,onNext:function}} props 
 */
export function VinScanModal ({
    isOpen,
    onBack,
    onClose,
    onVinScan,
    onNext,
}) {
    /* State | Effects */
    const [scanState, setScanState] = useState(null)                    // null | 'scanning' | 'scanned'
    // const [isLoading, setIsLoading] = useState(false);               // for future use (note: may just utilize 'scanState')
    const [scanResult, setScanResult] = useState('');
    const [scanResultJson, setScanResultJson] = useState('');

    // const [selectedScanType] = useState('barcode') // 'vin' | 'barcode' | ...
    const isLorensenQA = getConfig('marketplaceId') === '564'; // testing 'vin' preset (ocr-like scanner)
    const selectedScanType = useRef(isLorensenQA ? 'vin' : 'barcode') // 'vin' | 'barcode' | ...
    // let Anyline; // Can't initialize without attaching to element
    const Anyline = useRef();
    const trialKey = getConfig('anylineLicenseKey');

    const handleScanButtonPress = async () => {
        const initBarcodeConfig = {
            barcodeFormats: ['QR_CODE','DATA_MATRIX', 'CODE_39', 'CODE_128', 'PDF_417'],
            multiBarcode: false,
        }
        const anylineInitConfig = {
            /* meter, dialmeter, ocr, ehic, universalid_mrz, 
               germanid, drivingLicense, card_detect, lpt, vin, tin, tire_id, tire_size, barcode, container, containerVertical */
            config: {
                loadingScreen: createLoadingHtmlString(),
            },
            preset: selectedScanType.current, 
            license: trialKey,
            element: document.getElementById('vin-scanner__anyline-window'), // html container where anylineJS should be mounted to,
        }
        if (selectedScanType.current === 'barcode') {
            anylineInitConfig.config.barcodeConfig = {...initBarcodeConfig}
        }
        Anyline.current = anylineInit(anylineInitConfig);
        Anyline.current.onResult = function(result) {
            handleVinScanResult(result);
        };
        setScanState('scanning');
        Anyline.current.startScanning()
        .catch(scanErr => {
            console.error(scanErr?.message || JSON.stringify(scanErr));
            try {
                Anyline.current.dispose();
                setScanState(null);

            } catch (anylineCleanupError) {
                console.error(anylineCleanupError?.message)
            }
        });
    }
    
    const handleCancelPress = async (e) => {
        // e.preventDefault();
        // TODO: remove after testing phase
        console.log(
            "Anyline 'isLoaded' status | getState():  ",
            Anyline.current?.isLoaded,
            Anyline.current?.getState()
        );
        if (Anyline.current?.isLoaded) {
            try {
                if (Anyline.current.getState() === anylineStates.SCANNING) {
                    await Anyline.current.stopScanning().catch(stopScanningErr=>console.error(stopScanningErr));
                }
                Anyline.current.dispose();
            } catch (stopScanErr) {
                console.error(stopScanErr.message)
            }
        } else {
            console.log("No Anyline instance found")
        }
        resetScanData();
        onBack();
        
    }

    const handleCancelScanButtonPress = async (e) => {
        try {
            if (Anyline.current) {
                if (Anyline.current.getState() === anylineStates.SCANNING) {
                    await Anyline.current.stopScanning().catch(err=>console.error(err));
                }
                Anyline.current.dispose();
            }
        } catch(cleanupError) {
            console.error("An error occurred trying to clean up AnylineJS")
            console.error(cleanupError?.message || JSON.stringify(cleanupError))
        }
        setScanState(null)
    }
    
    /** 
     * Callback to assign to AnylineJS instance method - `Anyline.onResult` 
     * @param {{fullImage: string, result: object, scanTime: string}} scanResult 
     */
    const handleVinScanResult = async ({fullImage, result, scanTime}) => {
        // setScanResultJson(resultsStrPretty);
        const scanResultType = selectedScanType.current;
        const resultTxt = parseScanResult(result, scanResultType)
        const parsedResultTxt = parseVinResult(resultTxt)
        setScanResult(parsedResultTxt);
        setScanState('scanned');
        try {
            /* TODO - Remove after testing phase */
            console.log(JSON.stringify(result, null, 2))
            const currentState = await Anyline.current.getState();
            if (currentState === anylineStates.SCANNING) {
                // Documentation says anyline should stop scanning after first result,
                //  but that doesn't seem to be the case.
                await Anyline.current.stopScanning().catch(err=>console.error(err))
            }
            if (currentState && currentState !== anylineStates.DISPOSED) {
                await Anyline.current.dispose()
            }
        } catch (scanCleanupError) {
            console.error(scanCleanupError?.message)
        }
    }

    const handleConfirmPress = async (scannerResult) => {

        let anylineState = Anyline.current.getState();
        try {
            if (anylineState === anylineStates.SCANNING) {
                await Anyline.current.stopScanning();
                anylineState = Anyline.current.getState();
            }
            if (anylineState === anylineStates.STOPPED) {
                Anyline.current.dispose();
            }
            
        } catch (err) {
            console.error(err?.message)
        }
        resetScanData();
        onVinScan({result: scannerResult})
    }
    const resetScanData = () => {
        if (scanState !== null) {
            setScanState(null);
        }
        if (scanResult) {
            setScanResult('');
        }
        if (scanResultJson) {
            setScanResultJson('');
        }
    }
    const renderActionableButton = (currentScanState) => {
        
        switch (currentScanState) {
            /* Not yet scanned --> Scan Btn */
            case null:
                return null;
            case 'scanning':
            /* Scanning in progress --> Cancel Btn */
                return (
                <Button
                    btnText="Stop Scanning"
                    height="43px"
                    borderRadius="10px"
                    fontSize="16px"
                    iconName="Cross"
                    marginRight="10px"
                    iconSize={18}
                    iconColor={WHITE_01}
                    outlinedIconColor={WHITE_01}
                    outlined={false}
                    outlinedTextColor={WHITE_01}
                    outlinedBgColor={BLUE_01}
                    outlinedBorderColor={GREY_04}
                    onClickHandler={handleCancelScanButtonPress}
                />
                )
            case 'scanned': 
            /* Scanned --> 'Continue' button */
                return (
                <Button
                    btnText="Continue"
                    borderRadius="10px"
                    fontSize="16px"
                    height="43px"
                    iconColor={GREEN_01}
                    iconOnRight={true}
                    iconName="ChevronRight"
                    marginLeft="5px"
                    iconSize={18}
                    outlinedIconColor={WHITE_01}
                    outlined={false}
                    outlinedTextColor={WHITE_01}
                    outlinedBgColor={BLUE_01}
                    outlinedBorderColor={GREY_04}
                    onClickHandler={() => handleConfirmPress(scanResult)}
                    // disabled={isActiveScanning}
                    // loading={this.props.isLoading}
                />
                )
            default: 
                return null;
        }
    }
    const createLoadingHtmlString = () => {
        const styleObjToString = (styleObj) => {
            return Object.entries(styleObj).map(([cssProp, value]) => `${cssProp}: ${value};`).join(' ')
        }
        const logoCdnLink = makeAssetLink('logo.png');
        const labelStyle = {
            'text-align': 'center',
            'color': 'rgb(204,203,255)',
        }
        const logoStyle = {
            'object-fit': 'contain',
            'overflow': 'hidden',
            'width': '100%',
            'max-height': '12em',
            'box-shadow': '0px -3px 15px 10px rgba(238,241,242,0.5)',
            'background-color': 'rgba(251,254,251,0.95)',
            'border': '1px solid', 
            'border-color': 'rgba(244,244,211,0.75)',
          }
        const containerStyle = {
            'display': 'flex',
            'flex-direction': 'column',
            'align-content': 'space-evenly',
            'width': '100%',
            'height': '100%',
            'gap': '1em',
        }
        const logoStyleString = styleObjToString(logoStyle);
        const containerStyleString = styleObjToString(containerStyle);
        const labelStyleString = styleObjToString(labelStyle)
        const loadingHtmlString =  
        `<div style="${containerStyleString}">` +
            `<img alt="Loading" style="${logoStyleString}" src="${logoCdnLink}" />` +
            `<label class="core__scan-loading-flicker" style="${labelStyleString}">Loading Scanner . . .</label>` +
        `</div>`
        return loadingHtmlString

    }

    return (
        <Modal
            isOpen={isOpen}
            shouldCloseOnEsc
            style={styles.customStyles}
            contentLabel={'VINScanModal'}
            ariaHideApp={false}
            preventScroll={true}
        >   
            <div 
                id='vin-scanner-modal-container' 
                style={{
                    width: '325px', 
                    minHeight: '350px',
                    maxHeight: '450px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    overflow: 'hidden',
                }}
                >
                <div 
                    style={{
                        width: '100%',
                        height: '250px',
                        margin: '5px 5px',
                        backgroundColor: 'black',
                    }}
                    >
                        {scanState === null ? (
                            <p style={{
                                color: 'ghostwhite',
                                textAlign: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                zIndex: 130,
                                position: 'relative',
                                fontSize: '1.2em',
                            }}
                            onClick={handleScanButtonPress}
                            >
                            Tap here to start scanner.
                            </p>
                        ) : null}
                        <div 
                            id='vin-scanner__anyline-window'
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            
                        >
                        </div>
                </div>
                
                <hr style={{
                        borderColor: '#E5E5E5',
                        width: '100%',
                        alignSelf: 'center',
                        marginTop: 10,
                        marginBottom: 10,
                    }} 
                />
                {scanResult
                    ? (
                    <div 
                        style={{
                            width: '100%', 
                            border: '1px solid gray', 
                            padding: '0.25em 0.2em', 
                            display: 'flex', 
                            gap: '5px',
                            marginBottom: '0.5em',
                            textOverflow: 'ellipsis',
                            alignItems: 'center',
                        }}
                    >
                        <label style={{padding: '5px'}} >Result: </label>
                        <p style={{
                            margin: 'unset', 
                            fontFamily: 'monospace', 
                            fontSize: '1.2em', 
                            textAlign: 'center', 
                            width: '90%', 
                            wordWrap: 'break-word',
                        }}>
                            {scanResult}
                        </p>
                    </div>
                    )
                    : null
                }
                <div  
                    style={{
                        display: 'flex',
                        alignContent: 'space-around',
                        justifyContent: 'space-evenly',
                        width: '100%',
                        margin: '5px',
                    }}
                    >
                    {/* Cancel (back) button */}
                    <Button
                        borderRadius="10px"
                        btnText="Cancel"
                        fontSize="16px"
                        height="43px"
                        outlined={true}
                        outlinedTextColor={BLACK_02}
                        outlinedBgColor={GREY_05}
                        outlinedBorderColor={GREY_05}
                        onClickHandler={handleCancelPress}
                    />
                    {/* Scan | CancelScan | Continue button */}
                    {renderActionableButton(scanState)}
                </div>
            </div>

        </Modal>
    )
}

/**
 * Parse text value from `'vin'` or `'barcode'` scan `resultType`. 
 * @param {object} result 
 * @param {'vin' | 'barcode'} resultType 
 */
const parseScanResult = (result, resultType) => {
    if (resultType === 'barcode') {
        try {
            const barcodeResultVals = result.barcodeResult.barcodes.map(bcResult => bcResult.value);
            return barcodeResultVals.join('');

        } catch (bcParseErr) {
            return bcParseErr?.message;
        }
    }
    else if (resultType === 'vin') {
        return result.vinResult?.text
    }
    else {
        return JSON.stringify(result);
    }
}

/**
 * 
 * @param {string} vinResultStr 
 */
const parseVinResult = (vinResultStr) => {
    if (typeof vinResultStr !== 'string' || !vinResultStr ) {
        return vinResultStr;
    } else if (vinResultStr.length && vinResultStr < 4) {
        return vinResultStr;
    } else {
        // * * Copied from mobile code * *
        // NOTE: - Some qr codes have the vin plus a bunch of other comma separated info
        // so we have to look for the comma and isolate the VIN.
        let vinText = vinResultStr
        const commaIndex = vinText.indexOf(',');
        if (commaIndex > 0) {
            vinText = vinText.slice(0, commaIndex);
        }
        // handle weird vins
        if (vinText.length === 18) {
            const first = vinText.charAt(0);
            const last = vinText.charAt(18);
            if (first === 'I') {
                vinText = vinText.slice(1, 18);
            } else if (last === 'H' || last === 'M') {
                vinText = vinText.slice(0, 17);
            }
        }
        return vinText;
    }
}