//
import { uniq } from 'lodash';
import { FETCH_API } from '../middleware';
import { getConfig } from '../utils/helpers';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const fetchLogin = (mpId, user, pass) => {
  return {
    [FETCH_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
      endpoint: `login/?username=${user}&password=${pass}&mpId=${mpId}`,
      method: 'GET',
    },
  };
};

export const loadLogin = (mpId, user, pass) => {
  return dispatch => {
    return dispatch(fetchLogin(mpId, user, pass));
  };
};

export const REFRESH_APIKEY_REQUEST = 'REFRESH_APIKEY_REQUEST';
export const REFRESH_APIKEY_SUCCESS = 'REFRESH_APIKEY_SUCCESS';
export const REFRESH_APIKEY_FAILURE = 'REFRESH_APIKEY_FAILURE';

const fetchRefreshApiKey = (mpId, refreshToken) => {
  return {
    [FETCH_API]: {
      types: [
        REFRESH_APIKEY_REQUEST,
        REFRESH_APIKEY_SUCCESS,
        REFRESH_APIKEY_FAILURE,
      ],
      endpoint: `login?mpId=${mpId}&refreshToken=${refreshToken}&generateRefreshToken=true`,
      method: 'GET',
    },
  };
};

export const loadRefreshApiKey = (mpId, refreshToken) => {
  return dispatch => {
    return dispatch(fetchRefreshApiKey(mpId, refreshToken));
  };
};

export const MARKETPLACE_FEATURES_REQUEST = 'MARKETPLACE_FEATURES_REQUEST';
export const MARKETPLACE_FEATURES_SUCCESS = 'MARKETPLACE_FEATURES_SUCCESS';
export const MARKETPLACE_FEATURES_FAILURE = 'MARKETPLACE_FEATURES_FAILURE';

const fetchMarketplaceFeatures = mpId => {
  return {
    [FETCH_API]: {
      types: [
        MARKETPLACE_FEATURES_REQUEST,
        MARKETPLACE_FEATURES_SUCCESS,
        MARKETPLACE_FEATURES_FAILURE,
      ],
      endpoint: `get-marketplace-features` + `?mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadMarketplaceFeatures = mpId => {
  return dispatch => {
    return dispatch(fetchMarketplaceFeatures(mpId));
  };
};

export const SERVER_TIME_REQUEST = 'SERVER_TIME_REQUEST';
export const SERVER_TIME_SUCCESS = 'SERVER_TIME_SUCCESS';
export const SERVER_TIME_FAILURE = 'SERVER_TIME_FAILURE';

const fetchServerTime = mpId => {
  return {
    [FETCH_API]: {
      types: [SERVER_TIME_REQUEST, SERVER_TIME_SUCCESS, SERVER_TIME_FAILURE],
      endpoint: `currenttimestamp/?mpId=${mpId}`,
      method: 'GET',
    },
  };
};

export const loadServerTime = mpId => {
  return dispatch => {
    return dispatch(fetchServerTime(mpId));
  };
};

export const RESET_LOGIN = 'RESET_LOGIN';

export const resetLogin = () => {
  return {
    type: RESET_LOGIN,
  };
};

export const EVENTS_REQUEST = 'EVENTS_REQUEST';
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_FAILURE = 'EVENTS_FAILURE';

const fetchEvents = mpId => {
  return {
    [FETCH_API]: {
      types: [EVENTS_REQUEST, EVENTS_SUCCESS, EVENTS_FAILURE],
      endpoint:
        `event-list/` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&includeAllEvents=true` +
        `&includePrivateEvents=true`,
      method: 'GET',
    },
  };
};

export const loadEvents = mpId => {
  return dispatch => {
    return dispatch(fetchEvents(mpId));
  };
};

export const ALL_RUNLISTS_REQUEST = 'ALL_RUNLISTS_REQUEST';
export const ALL_RUNLISTS_SUCCESS = 'ALL_RUNLISTS_SUCCESS';
export const ALL_RUNLISTS_FAILURE = 'ALL_RUNLISTS_FAILURE';

const fetchAllRunlists = mpId => {
  return {
    [FETCH_API]: {
      types: [ALL_RUNLISTS_REQUEST, ALL_RUNLISTS_SUCCESS, ALL_RUNLISTS_FAILURE],
      endpoint:
        `event-list/` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&includeAllEvents=true` +
        `&includePrivateEvents=true`,
      method: 'GET',
    },
  };
};

export const loadAllRunlists = mpId => {
  return dispatch => {
    return dispatch(fetchAllRunlists(mpId));
  };
};

export const RESET_EVENTS = 'RESET_EVENTS';

export const resetEvents = () => {
  return {
    type: RESET_EVENTS,
  };
};

export const MARKETPLACES_REQUEST = 'MARKETPLACES_REQUEST';
export const MARKETPLACES_SUCCESS = 'MARKETPLACES_SUCCESS';
export const MARKETPLACES_FAILURE = 'MARKETPLACES_FAILURE';

const fetchMarketplaces = mpId => {
  return {
    [FETCH_API]: {
      types: [MARKETPLACES_REQUEST, MARKETPLACES_SUCCESS, MARKETPLACES_FAILURE],
      endpoint: `marketplace-list/?mpId=${mpId}&includeAllEvents=true`,
      method: 'GET',
    },
  };
};

export const loadMarketplaces = mpId => {
  return dispatch => {
    return dispatch(fetchMarketplaces(mpId));
  };
};

export const RESET_MARKETPLACES = 'RESET_MARKETPLACES';

export const resetMarketplaces = () => {
  return {
    type: RESET_MARKETPLACES,
  };
};

export const RUNLIST_REQUEST = 'RUNLIST_REQUEST';
export const RUNLIST_SUCCESS = 'RUNLIST_SUCCESS';
export const RUNLIST_FAILURE = 'RUNLIST_FAILURE';

const fetchRunList = (mpId, eventId) => {
  return {
    [FETCH_API]: {
      types: [RUNLIST_REQUEST, RUNLIST_SUCCESS, RUNLIST_FAILURE],
      endpoint: `item-list/?mpId=${mpId}&eventId=${eventId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadRunList = (mpId, eventId) => {
  return dispatch => {
    return dispatch(fetchRunList(mpId, eventId));
  };
};

export const ITEM_REQUEST = 'ITEM_REQUEST';
export const ITEM_SUCCESS = 'ITEM_SUCCESS';
export const ITEM_FAILURE = 'ITEM_FAILURE';

const fetchItem = (mpId, id) => {
  return {
    [FETCH_API]: {
      types: [ITEM_REQUEST, ITEM_SUCCESS, ITEM_FAILURE],
      endpoint: `item` + `?itemId=${id}` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadItem = (mpId, id) => {
  return dispatch => {
    return dispatch(fetchItem(mpId, id));
  };
};

export const PARENT_ITEM_REQUEST = 'PARENT_ITEM_REQUEST';
export const PARENT_ITEM_SUCCESS = 'PARENT_ITEM_SUCCESS';
export const PARENT_ITEM_FAILURE = 'PARENT_ITEM_FAILURE';

const fetchParentItem = (mpId, id) => {
  return {
    [FETCH_API]: {
      types: [PARENT_ITEM_REQUEST, PARENT_ITEM_SUCCESS, PARENT_ITEM_FAILURE],
      endpoint: `item?mpId=${mpId}&itemId=${id}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadParentItem = (mpId, id) => {
  return dispatch => {
    return dispatch(fetchParentItem(mpId, id));
  };
};

export const RESET_PARENT_ITEM = 'RESET_PARENT_ITEM';

export const resetParentItem = () => {
  return {
    type: RESET_PARENT_ITEM,
  };
};

export const RESET_ITEM = 'RESET_ITEM';

export const resetItem = () => {
  return {
    type: RESET_ITEM,
  };
};

export const ITEM_SAVE_NOTES_REQUEST = 'ITEM_SAVE_NOTES_REQUEST';
export const ITEM_SAVE_NOTES_SUCCESS = 'ITEM_SAVE_NOTES_SUCCESS';
export const ITEM_SAVE_NOTES_FAILURE = 'ITEM_SAVE_NOTES_FAILURE';

export const fetchSaveItemNotes = (mpId, itemId, notes = '') => {
  return {
    [FETCH_API]: {
      types: [
        ITEM_SAVE_NOTES_REQUEST,
        ITEM_SAVE_NOTES_SUCCESS,
        ITEM_SAVE_NOTES_FAILURE,
      ],
      endpoint:
        `item-save-notes` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&notes=${notes}`,
      method: 'GET',
    },
  };
};

export const loadSaveItemNotes = (mpId, itemId, notes) => {
  return dispatch => {
    return dispatch(fetchSaveItemNotes(mpId, itemId, notes));
  };
};

export const BID_HISTORY_REQUEST = 'BID_HISTORY_REQUEST';
export const BID_HISTORY_SUCCESS = 'BID_HISTORY_SUCCESS';
export const BID_HISTORY_FAILURE = 'BID_HISTORY_FAILURE';

const fetchBidHistory = (mpId, itemId) => {
  return {
    [FETCH_API]: {
      types: [BID_HISTORY_REQUEST, BID_HISTORY_SUCCESS, BID_HISTORY_FAILURE],
      endpoint: `bid-history?mpId=${mpId}&apiKey=&itemId=${itemId}`,
      method: 'GET',
    },
  };
};

export const loadBidHistory = (mpId, itemId) => {
  return dispatch => {
    return dispatch(fetchBidHistory(mpId, itemId));
  };
};

export const RESET_BID_HISTORY = 'RESET_BID_HISTORY';

export const resetBidHistory = () => {
  return {
    type: RESET_BID_HISTORY,
  };
};

export const EVENT_INFO_REQUEST = 'EVENT_INFO_REQUEST';
export const EVENT_INFO_SUCCESS = 'EVENT_INFO_SUCCESS';
export const EVENT_INFO_FAILURE = 'EVENT_INFO_FAILURE';

const fetchEventInfo = (mpId, eventId) => {
  return {
    [FETCH_API]: {
      types: [EVENT_INFO_REQUEST, EVENT_INFO_SUCCESS, EVENT_INFO_FAILURE],
      endpoint: `event-info?mpId=${mpId}&eventId=${eventId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadEventInfo = (mpId, eventId) => {
  return dispatch => {
    return dispatch(fetchEventInfo(mpId, eventId));
  };
};

export const RESET_EVENT_INFO = 'RESET_EVENT_INFO';

export const resetEventInfo = () => {
  return {
    type: RESET_EVENT_INFO,
  };
};

// my account buyer and seller

export const ACCOUNT_SUMMARY_REQUEST = 'ACCOUNT_SUMMARY_REQUEST';
export const ACCOUNT_SUMMARY_SUCCESS = 'ACCOUNT_SUMMARY_SUCCESS';
export const ACCOUNT_SUMMARY_FAILURE = 'ACCOUNT_SUMMARY_FAILURE';

const fetchAccountSummary = (mpId, accountId, eventId) => {
  return {
    [FETCH_API]: {
      types: [
        ACCOUNT_SUMMARY_REQUEST,
        ACCOUNT_SUMMARY_SUCCESS,
        ACCOUNT_SUMMARY_FAILURE,
      ],
      endpoint:
        `myaccount` +
        `?list=AccountSummary` +
        `&accountId=${accountId}` +
        `&eventId=${eventId}` +
        `&mpId=${mpId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadAccountSummary = (mpId, accountId, eventId) => {
  return dispatch => {
    return dispatch(fetchAccountSummary(mpId, accountId, eventId));
  };
};

export const RESET_ACCOUNT_SUMMARY = 'RESET_ACCOUNT_SUMMARY';

export const resetAccountSummary = () => {
  return {
    type: RESET_ACCOUNT_SUMMARY,
  };
};

export const GATE_PASS_REQUEST = 'GATE_PASS_REQUEST';
export const GATE_PASS_SUCCESS = 'GATE_PASS_SUCCESS';
export const GATE_PASS_FAILURE = 'GATE_PASS_FAILURE';

const fetchGatePass = mpId => {
  return {
    [FETCH_API]: {
      types: [GATE_PASS_REQUEST, GATE_PASS_SUCCESS, GATE_PASS_FAILURE],
      endpoint:
        `myaccount` +
        `?list=ams-gatepass` +
        `&sublist=''` +
        `&mpId=${mpId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadGatePass = mpId => {
  return dispatch => {
    return dispatch(fetchGatePass(mpId));
  };
};

export const RESET_GATE_PASS = 'RESET_GATE_PASS';

export const resetGatePass = () => {
  return {
    type: RESET_GATE_PASS,
  };
};

export const AUCTION_ARBITRATION_REQUEST = 'AUCTION_ARBITRATION_REQUEST';
export const AUCTION_ARBITRATION_SUCCESS = 'AUCTION_ARBITRATION_SUCCESS';
export const AUCTION_ARBITRATION_FAILURE = 'AUCTION_ARBITRATION_FAILURE';

const fetchAuctionArbitration = mpId => {
  return {
    [FETCH_API]: {
      types: [
        AUCTION_ARBITRATION_REQUEST,
        AUCTION_ARBITRATION_SUCCESS,
        AUCTION_ARBITRATION_FAILURE,
      ],
      endpoint:
        `myaccount` +
        `?list=ams-arbitration` +
        `&accountId=0` +
        `&mpId=${mpId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadAuctionArbitration = mpId => {
  return dispatch => {
    return dispatch(fetchAuctionArbitration(mpId));
  };
};

export const RESET_AUCTION_ARBITRATION = 'RESET_AUCTION_ARBITRATION';

export const resetAuctionArbitration = () => {
  return {
    type: RESET_AUCTION_ARBITRATION,
  };
};

// my account buyer only

export const BUYER_BIDS_REQUEST = 'BUYER_BIDS_REQUEST';
export const BUYER_BIDS_SUCCESS = 'BUYER_BIDS_SUCCESS';
export const BUYER_BIDS_FAILURE = 'BUYER_BIDS_FAILURE';

const fetchBuyerBids = mpId => {
  return {
    [FETCH_API]: {
      types: [BUYER_BIDS_REQUEST, BUYER_BIDS_SUCCESS, BUYER_BIDS_FAILURE],
      endpoint:
        `myaccount` + `?list=MyActiveBids` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerBids = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerBids(mpId));
  };
};

export const RESET_BUYER_BIDS = 'RESET_BUYER_BIDS';

export const resetBuyerBids = () => {
  return { type: RESET_BUYER_BIDS };
};

export const BUYER_OFFERS_REQUEST = 'BUYER_OFFERS_REQUEST';
export const BUYER_OFFERS_SUCCESS = 'BUYER_OFFERS_SUCCESS';
export const BUYER_OFFERS_FAILURE = 'BUYER_OFFERS_FAILURE';

const fetchBuyerOffers = mpId => {
  return {
    [FETCH_API]: {
      types: [BUYER_OFFERS_REQUEST, BUYER_OFFERS_SUCCESS, BUYER_OFFERS_FAILURE],
      endpoint:
        `myaccount` + `?list=ActiveOffers` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerOffers = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerOffers(mpId));
  };
};

export const RESET_BUYER_OFFERS = 'RESET_BUYER_OFFERS';

export const resetBuyerOffers = () => {
  return { type: RESET_BUYER_OFFERS };
};

export const BUYER_IFS_REQUEST = 'BUYER_IFS_REQUEST';
export const BUYER_IFS_SUCCESS = 'BUYER_IFS_SUCCESS';
export const BUYER_IFS_FAILURE = 'BUYER_IFS_FAILURE';

const fetchBuyerIfs = mpId => {
  return {
    [FETCH_API]: {
      types: [BUYER_IFS_REQUEST, BUYER_IFS_SUCCESS, BUYER_IFS_FAILURE],
      endpoint:
        `myaccount` +
        `?list=PurchasedIf` +
        `&accountId=0` +
        `&mpId=${mpId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerIfs = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerIfs(mpId));
  };
};

export const RESET_BUYER_IFS = 'RESET_BUYER_IFS';

export const resetBuyerIfs = () => {
  return {
    type: RESET_BUYER_IFS,
  };
};

export const BUYER_PURCHASED_REQUEST = 'BUYER_PURCHASED_REQUEST';
export const BUYER_PURCHASED_SUCCESS = 'BUYER_PURCHASED_SUCCESS';
export const BUYER_PURCHASED_FAILURE = 'BUYER_PURCHASED_FAILURE';

const fetchBuyerPurchased = mpId => {
  return {
    [FETCH_API]: {
      types: [
        BUYER_PURCHASED_REQUEST,
        BUYER_PURCHASED_SUCCESS,
        BUYER_PURCHASED_FAILURE,
      ],
      endpoint:
        `myaccount` +
        `?list=Purchased` +
        `&accountId=0` +
        `&mpId=${mpId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};
/** **2024.09.19** - 
 * `arbitrationStatus`, `enableAddArbitrationComment`, `enableRequestArbitration`, `enableCancelArbitration` added to response.
 * 
 * @param {string} mpId 
 */
export const loadBuyerPurchased = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerPurchased(mpId));
  };
};

export const RESET_BUYER_PURCHASED = 'RESET_BUYER_PURCHASED';

export const resetBuyerPurchased = () => {
  return {
    type: RESET_BUYER_PURCHASED,
  };
};

export const BUYER_TITLES_REQUEST = 'BUYER_TITLES_REQUEST';
export const BUYER_TITLES_SUCCESS = 'BUYER_TITLES_SUCCESS';
export const BUYER_TITLES_FAILURE = 'BUYER_TITLES_FAILURE';

const fetchBuyerTitles = mpId => {
  return {
    [FETCH_API]: {
      types: [BUYER_TITLES_REQUEST, BUYER_TITLES_SUCCESS, BUYER_TITLES_FAILURE],
      endpoint:
        `myaccount` +
        `?list=ams-title` +
        `&accountId=0` +
        `&buyerOrSeller=b` +
        `&mpId=${mpId}` +
        `&apiKey=`,
    },
    method: 'GET',
  };
};

export const loadBuyerTitles = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerTitles(mpId));
  };
};

export const RESET_BUYER_TITLES = 'RESET_BUYER_TITLES';

export const resetBuyerTitles = () => {
  return {
    type: RESET_BUYER_TITLES,
  };
};

export const BUYER_TRANSPORTATION_REQUEST = 'BUYER_TRANSPORTATION_REQUEST';
export const BUYER_TRANSPORTATION_SUCCESS = 'BUYER_TRANSPORTATION_SUCCESS';
export const BUYER_TRANSPORTATION_FAILURE = 'BUYER_TRANSPORTATION_FAILURE';

const fetchBuyerTransportation = mpId => {
  return {
    [FETCH_API]: {
      types: [
        BUYER_TRANSPORTATION_REQUEST,
        BUYER_TRANSPORTATION_SUCCESS,
        BUYER_TRANSPORTATION_FAILURE,
      ],
      endpoint:
        `myaccount` + `?list=Transportation` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerTransportation = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerTransportation(mpId));
  };
};

export const RESET_BUYER_TRANSPORTATION = 'RESET_BUYER_TRANSPORTATION';

export const resetBuyerTransportation = () => {
  return { type: RESET_BUYER_TRANSPORTATION };
};

// my account seller only

export const SELLER_INVENTORY_REQUEST = 'SELLER_INVENTORY_REQUEST';
export const SELLER_INVENTORY_SUCCESS = 'SELLER_INVENTORY_SUCCESS';
export const SELLER_INVENTORY_FAILURE = 'SELLER_INVENTORY_FAILURE';

const fetchSellerInventory = mpId => {
  return {
    [FETCH_API]: {
      types: [
        SELLER_INVENTORY_REQUEST,
        SELLER_INVENTORY_SUCCESS,
        SELLER_INVENTORY_FAILURE,
      ],
      endpoint: `myaccount` + `?list=Inventory` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSellerInventory = mpId => {
  return dispatch => {
    return dispatch(fetchSellerInventory(mpId));
  };
};

export const RESET_SELLER_INVENTORY = 'RESET_SELLER_INVENTORY';

export const resetSellerInventory = () => {
  return {
    type: RESET_SELLER_INVENTORY,
  };
};

export const SELLER_BIDS_REQUEST = 'SELLER_BIDS_REQUEST';
export const SELLER_BIDS_SUCCESS = 'SELLER_BIDS_SUCCESS';
export const SELLER_BIDS_FAILURE = 'SELLER_BIDS_FAILURE';

const fetchSellerBids = mpId => {
  return {
    [FETCH_API]: {
      types: [SELLER_BIDS_REQUEST, SELLER_BIDS_SUCCESS, SELLER_BIDS_FAILURE],
      endpoint:
        `myaccount` + `?list=BidsReceived` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSellerBids = mpId => {
  return dispatch => {
    return dispatch(fetchSellerBids(mpId));
  };
};

export const DEALERS_REQUEST = 'DEALERS_REQUEST';
export const DEALERS_SUCCESS = 'DEALERS_SUCCESS';
export const DEALERS_FAILURE = 'DEALERS_FAILURE';

const fetchDealers = mpId => {
  return {
    [FETCH_API]: {
      types: [DEALERS_REQUEST, DEALERS_SUCCESS, DEALERS_FAILURE],
      endpoint: `get-dealers-with-inventory?apiKey=&mpId=${mpId}`,
      method: 'GET',
    },
  };
};

export const loadDealers = mpId => {
  return dispatch => {
    return dispatch(fetchDealers(mpId));
  };
};

export const SELLER_EXT_OFFERS_REQUEST = 'SELLER_EXT_OFFERS_REQUEST';
export const SELLER_EXT_OFFERS_SUCCESS = 'SELLER_EXT_OFFERS_SUCCESS';
export const SELLER_EXT_OFFERS_FAILURE = 'SELLER_EXT_OFFERS_FAILURE';

const fetchSellerExtOffers = (mpId, dealerId) => {
  return {
    [FETCH_API]: {
      types: [
        SELLER_EXT_OFFERS_REQUEST,
        SELLER_EXT_OFFERS_SUCCESS,
        SELLER_EXT_OFFERS_FAILURE,
      ],
      endpoint: `ext-offer-inventory?apiKey=&mpId=${mpId}&dealerId=${dealerId}`,
      method: 'GET',
    },
  };
};

export const loadSellerExtOffers = (mpId, dealerId) => {
  return dispatch => {
    return dispatch(fetchSellerExtOffers(mpId, dealerId));
  };
};

export const EXT_OFFER_UPDATE_REQUEST = 'EXT_OFFER_UPDATE_REQUEST';
export const EXT_OFFER_UPDATE_SUCCESS = 'EXT_OFFER_UPDATE_SUCCESS';
export const EXT_OFFER_UPDATE_FAILURE = 'EXT_OFFER_UPDATE_FAILURE';

const fetchExtOfferUpdate = (mpId, offerId, requestUid, vin) => {
  return {
    [FETCH_API]: {
      types: [
        EXT_OFFER_UPDATE_REQUEST,
        EXT_OFFER_UPDATE_SUCCESS,
        EXT_OFFER_UPDATE_FAILURE,
      ],
      endpoint: `ext-offer-update?apiKey=&mpId=${mpId}&offerId=${offerId}&requestUid=${requestUid}&vin=${vin}`,
      method: 'POST',
    },
  };
};

export const loadExtOfferUpdate = (
  mpId,
  offerId = 0,
  requestUid = '',
  vin = ''
) => {
  return dispatch => {
    return dispatch(fetchExtOfferUpdate(mpId, offerId, requestUid, vin));
  };
};

export const RESET_SELLER_BIDS = 'RESET_SELLER_BIDS';

export const resetSellerBids = () => {
  return {
    type: RESET_SELLER_BIDS,
  };
};

export const SELLER_OFFERS_REQUEST = 'SELLER_OFFERS_REQUEST';
export const SELLER_OFFERS_SUCCESS = 'SELLER_OFFERS_SUCCESS';
export const SELLER_OFFERS_FAILURE = 'SELLER_OFFERS_FAILURE';

const fetchSellerOffers = mpId => {
  return {
    [FETCH_API]: {
      types: [
        SELLER_OFFERS_REQUEST,
        SELLER_OFFERS_SUCCESS,
        SELLER_OFFERS_FAILURE,
      ],
      endpoint:
        `myaccount` + `?list=OffersReceived` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSellerOffers = mpId => {
  return dispatch => {
    return dispatch(fetchSellerOffers(mpId));
  };
};

export const RESET_SELLER_OFFERS = 'RESET_SELLER_OFFERS';

export const resetSellerOffers = () => {
  return {
    type: RESET_SELLER_OFFERS,
  };
};

export const SELLER_IFS_REQUEST = 'SELLER_IFS_REQUEST';
export const SELLER_IFS_SUCCESS = 'SELLER_IFS_SUCCESS';
export const SELLER_IFS_FAILURE = 'SELLER_IFS_FAILURE';

const fetchSellerIfs = mpId => {
  return {
    [FETCH_API]: {
      types: [SELLER_IFS_REQUEST, SELLER_IFS_SUCCESS, SELLER_IFS_FAILURE],
      endpoint: `myaccount` + `?list=SoldIf` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSellerIfs = mpId => {
  return dispatch => {
    return dispatch(fetchSellerIfs(mpId));
  };
};

export const RESET_SELLER_IFS = 'RESET_SELLER_IFS';

export const resetSellerIfs = () => {
  return {
    type: RESET_SELLER_IFS,
  };
};

export const SELLER_SOLD_REQUEST = 'SELLER_SOLD_REQUEST';
export const SELLER_SOLD_SUCCESS = 'SELLER_SOLD_SUCCESS';
export const SELLER_SOLD_FAILURE = 'SELLER_SOLD_FAILURE';

const fetchSellerSold = mpId => {
  return {
    [FETCH_API]: {
      types: [SELLER_SOLD_REQUEST, SELLER_SOLD_SUCCESS, SELLER_SOLD_FAILURE],
      endpoint: `myaccount` + `?list=Sold` + `&mpId=${mpId}` + `&apiKey=`,
      method: 'GET',
    },
  };
};
/** **2024.09.19** - `arbitrationStatus`, `enableAddArbitrationComment` added to response.
 * 
 * @param {string} mpId 
 * @returns {Dispatch<{[key:string]:any, arbitrationStatus:string, enableAddArbitrationComment:0|1}>}
 */
export const loadSellerSold = mpId => {
  return dispatch => {
    return dispatch(fetchSellerSold(mpId));
  };
};

export const RESET_SELLER_SOLD = 'RESET_SELLER_SOLD';

export const resetSellerSold = () => {
  return {
    type: RESET_SELLER_SOLD,
  };
};

export const SELLER_TITLES_REQUEST = 'SELLER_TITLES_REQUEST';
export const SELLER_TITLES_SUCCESS = 'SELLER_TITLES_SUCCESS';
export const SELLER_TITLES_FAILURE = 'SELLER_TITLES_FAILURE';

const fetchSellerTitles = mpId => {
  return {
    [FETCH_API]: {
      types: [
        SELLER_TITLES_REQUEST,
        SELLER_TITLES_SUCCESS,
        SELLER_TITLES_FAILURE,
      ],
      endpoint:
        `myaccount` +
        `?list=ams-title` +
        `&accountId=0` +
        `&buyerOrSeller=s` +
        `&mpId=${mpId}` +
        `&apiKey=`,
    },
  };
};

export const loadSellerTitles = mpId => {
  return dispatch => {
    return dispatch(fetchSellerTitles(mpId));
  };
};

export const RESET_SELLER_TITLES = 'RESET_SELLER_TITLES';

export const resetSellerTitles = () => {
  return {
    type: RESET_SELLER_TITLES,
  };
};

export const ADD_TRANSPORTATION_ORDER_ID_REQUEST =
  'ADD_TRANSPORTATION_ORDER_ID_REQUEST';
export const ADD_TRANSPORTATION_ORDER_ID_SUCCESS =
  'ADD_TRANSPORTATION_ORDER_ID_SUCCESS';
export const ADD_TRANSPORTATION_ORDER_ID_FAILURE =
  'ADD_TRANSPORTATION_ORDER_ID_FAILURE';

const fetchTransportationSaveOrderId = (mpId, transactionId, orderId) => {
  return {
    [FETCH_API]: {
      types: [
        ADD_TRANSPORTATION_ORDER_ID_REQUEST,
        ADD_TRANSPORTATION_ORDER_ID_SUCCESS,
        ADD_TRANSPORTATION_ORDER_ID_FAILURE,
      ],
      endpoint:
        `transportation-save-orderid` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&transId=${transactionId}` +
        `&orderId=${orderId}`,
      method: 'GET',
    },
  };
};

export const loadTransportationSaveOrderId = (mpId, transactionId, orderId) => {
  return dispatch => {
    return dispatch(
      fetchTransportationSaveOrderId(mpId, transactionId, orderId)
    );
  };
};

export const ITEM_OFFER_REQUEST = 'ITEM_OFFER_REQUEST';
export const ITEM_OFFER_SUCCESS = 'ITEM_OFFER_SUCCESS';
export const ITEM_OFFER_FAILURE = 'ITEM_OFFER_FAILURE';

const fetchItemOffer = (
  mpId,
  itemId,
  offerTypeId,
  amount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return {
    [FETCH_API]: {
      types: [ITEM_OFFER_REQUEST, ITEM_OFFER_SUCCESS, ITEM_OFFER_FAILURE],
      endpoint:
        `item-offer` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&itemId=${itemId}` +
        `&offerTypeId=${offerTypeId}` +
        `&amount=${amount}` +
        `&userId=${userId}` +
        `&paymentType=${paymentType}` +
        `&transportationFeeId=${transportationFeeId}` +
        `&transportFee=${transportFee}` +
        `&transactionSourceId=2`,

      method: 'GET',
    },
  };
};

export const loadItemOffer = (
  mpId,
  itemId,
  offerTypeId,
  amount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return dispatch => {
    return dispatch(
      fetchItemOffer(
        mpId,
        itemId,
        offerTypeId,
        amount,
        userId,
        paymentType,
        transportationFeeId,
        transportFee
      )
    );
  };
};

export const ITEM_OFFER_RESET = 'ITEM_OFFER_RESET';

export const resetOffer = () => {
  return {
    type: ITEM_OFFER_RESET,
  };
};

export const ITEM_BID_REQUEST = 'ITEM_BID_REQUEST';
export const ITEM_BID_SUCCESS = 'ITEM_BID_SUCCESS';
export const ITEM_BID_FAILURE = 'ITEM_BID_FAILURE';

const fetchItemBid = (
  mpId,
  itemId,
  bidType,
  bidAmount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return {
    [FETCH_API]: {
      types: [ITEM_BID_REQUEST, ITEM_BID_SUCCESS, ITEM_BID_FAILURE],
      endpoint:
        `item-bid` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&itemId=${itemId}` +
        `&bidTypeId=${bidType}` +
        `&amount=${bidAmount}` +
        `&userId=${userId}` +
        `&paymentType=${paymentType}` +
        `&transportationFeeId=${transportationFeeId}` +
        `&transportFee=${transportFee}` +
        `&transactionSourceId=2`,
      method: 'GET',
    },
  };
};

export const loadItemBid = (
  mpId,
  itemId,
  bidType,
  bidAmount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return dispatch => {
    return dispatch(
      fetchItemBid(
        mpId,
        itemId,
        bidType,
        bidAmount,
        userId,
        paymentType,
        transportationFeeId,
        transportFee
      )
    );
  };
};

export const ITEM_BID_RESET = 'ITEM_BID_RESET';

export const resetBid = () => {
  return {
    type: ITEM_BID_RESET,
  };
};

export const ITEM_BUY_REQUEST = 'ITEM_BUY_REQUEST';
export const ITEM_BUY_SUCCESS = 'ITEM_BUY_SUCCESS';
export const ITEM_BUY_FAILURE = 'ITEM_BUY_FAILURE';

const fetchItemBuy = (
  mpId,
  itemId,
  amount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return {
    [FETCH_API]: {
      types: [ITEM_BUY_REQUEST, ITEM_BUY_SUCCESS, ITEM_BUY_FAILURE],
      endpoint:
        `item-buy` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&itemId=${itemId}` +
        `&amount=${amount}` +
        `&userId=${userId}` +
        `&paymentType=${paymentType}` +
        `&transportationFeeId=${transportationFeeId}` +
        `&transportFee=${transportFee}` +
        `&transactionSourceId=2`,
      method: 'GET',
    },
  };
};

export const loadItemBuy = (
  mpId,
  itemId,
  amount,
  userId,
  paymentType,
  transportationFeeId,
  transportFee
) => {
  return dispatch => {
    return dispatch(
      fetchItemBuy(
        mpId,
        itemId,
        amount,
        userId,
        paymentType,
        transportationFeeId,
        transportFee
      )
    );
  };
};

export const ITEM_BUY_RESET = 'ITEM_BUY_RESET';

export const resetBuy = () => {
  return {
    type: ITEM_BUY_RESET,
  };
};

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

const fetchUserProfile = mpId => {
  return {
    [FETCH_API]: {
      types: [USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_FAILURE],
      endpoint:
        `user-profile` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&userId=${localStorage.getItem('userId')}`,
      method: 'GET',
    },
  };
};

export const loadUserProfile = mpId => {
  return dispatch => {
    return dispatch(fetchUserProfile(mpId));
  };
};

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

const fetchUpdateUserProfile = (mpId, userProfile = {}) => {
  return {
    [FETCH_API]: {
      types: [
        UPDATE_USER_PROFILE_REQUEST,
        UPDATE_USER_PROFILE_SUCCESS,
        UPDATE_USER_PROFILE_FAILURE,
      ],
      endpoint:
        `update-user-profile` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&userId=${localStorage.getItem('userId')}`,
      body: JSON.stringify(userProfile),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadUpdateUserProfile = (mpId, userProfile) => {
  return dispatch => {
    return dispatch(fetchUpdateUserProfile(mpId, userProfile));
  };
};

export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';

export const restUserProfile = () => {
  return {
    type: RESET_USER_PROFILE,
  };
};

export const WATCHLIST_REQUEST = 'WATCHLIST_REQUEST';
export const WATCHLIST_SUCCESS = 'WATCHLIST_SUCCESS';
export const WATCHLIST_FAILURE = 'WATCHLIST_FAILURE';

const fetchWatchlist = mpId => {
  return {
    [FETCH_API]: {
      types: [WATCHLIST_REQUEST, WATCHLIST_SUCCESS, WATCHLIST_FAILURE],
      endpoint: `watchlist?apiKey=&mpId=${mpId}`,
      method: 'GET',
    },
  };
};

export const loadWatchlist = mpId => {
  return dispatch => {
    return dispatch(fetchWatchlist(mpId));
  };
};

export const REPORT_SUMMARY_REQUEST = 'REPORT_SUMMARY_REQUEST';
export const REPORT_SUMMARY_SUCCESS = 'REPORT_SUMMARY_SUCCESS';
export const REPORT_SUMMARY_FAILURE = 'REPORT_SUMMARY_FAILURE';

const fetchReportSummary = mpId => {
  return {
    [FETCH_API]: {
      types: [
        REPORT_SUMMARY_REQUEST,
        REPORT_SUMMARY_SUCCESS,
        REPORT_SUMMARY_FAILURE,
      ],
      endpoint:
        `seller-report?mpId=${mpId}&apiKey=` +
        `&report=SummaryStats&userId=${localStorage.getItem('userId')}`,
      method: 'GET',
    },
  };
};

export const loadReportSummary = mpId => {
  return dispatch => {
    return dispatch(fetchReportSummary(mpId));
  };
};

export const ADD_WATCH_LIST_REQUEST = 'ADD_WATCH_LIST_REQUEST';
export const ADD_WATCH_LIST_SUCCESS = 'ADD_WATCH_LIST_SUCCESS';
export const ADD_WATCH_LIST_FAILURE = 'ADD_WATCH_LIST_FAILURE';

const fetchAddWatchList = (marketplaceId, itemId) => {
  return {
    [FETCH_API]: {
      types: [
        ADD_WATCH_LIST_REQUEST,
        ADD_WATCH_LIST_SUCCESS,
        ADD_WATCH_LIST_FAILURE,
      ],
      endpoint:
        `watchlist-add` +
        `?apiKey=` +
        `&mpId=${marketplaceId}` +
        `&itemList=${itemId}`,
      method: 'POST',
    },
  };
};

export const loadAddWatchList = (marketplaceId, itemId) => {
  return dispatch => {
    return dispatch(fetchAddWatchList(marketplaceId, itemId));
  };
};

export const RESET_ADD_WATCH_LIST = 'RESET_ADD_WATCH_LIST';

export const resetAddWatchList = () => {
  return {
    type: RESET_ADD_WATCH_LIST,
  };
};

export const REMOVE_WATCH_LIST_REQUEST = 'REMOVE_WATCH_LIST_REQUEST';
export const REMOVE_WATCH_LIST_SUCCESS = 'REMOVE_WATCH_LIST_SUCCESS';
export const REMOVE_WATCH_LIST_FAILURE = 'REMOVE_WATCH_LIST_FAILURE';

const fetchRemoveWatchList = (marketplaceId, itemId) => {
  return {
    [FETCH_API]: {
      types: [
        REMOVE_WATCH_LIST_REQUEST,
        REMOVE_WATCH_LIST_SUCCESS,
        REMOVE_WATCH_LIST_FAILURE,
      ],
      endpoint: `watchlist-remove?apiKey=&mpId=${marketplaceId}&itemList=${itemId}`,
      method: 'POST',
    },
  };
};

export const loadRemoveWatchList = (marketplaceId, itemId) => {
  return dispatch => {
    return dispatch(fetchRemoveWatchList(marketplaceId, itemId));
  };
};

export const RESET_REMOVE_WATCH_LIST = 'RESET_REMOVE_WATCH_LIST';

export const resetRemoveWatchList = () => {
  return {
    type: RESET_REMOVE_WATCH_LIST,
  };
};

export const EVENT_COMMAND_REQUEST = 'EVENT_COMMAND_REQUEST';
export const EVENT_COMMAND_SUCCESS = 'EVENT_COMMAND_SUCCESS';
export const EVENT_COMMAND_FAILURE = 'EVENT_COMMAND_FAILURE';

const fetchEventCommand = (
  mpId,
  eventId,
  itemId,
  amount,
  statusId,
  cmdTypeId
) => {
  return {
    [FETCH_API]: {
      types: [
        EVENT_COMMAND_REQUEST,
        EVENT_COMMAND_SUCCESS,
        EVENT_COMMAND_FAILURE,
      ],
      endpoint:
        `event-command/?mpId=${mpId}&eventId=${eventId}` +
        `&itemId=${itemId}&amount=${amount}&statusId=${statusId}` +
        `&message=''&cmdTypeId=${cmdTypeId}` +
        `&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadEventCommand = (
  mpId,
  eventId,
  itemId,
  amount,
  statusId,
  cmdTypeId
) => {
  return dispatch => {
    return dispatch(
      fetchEventCommand(mpId, eventId, itemId, amount, statusId, cmdTypeId)
    );
  };
};

export const RESET_EVENT_COMMAND = 'RESET_EVENT_COMMAND';

export const resetEventCommand = () => {
  return {
    type: RESET_EVENT_COMMAND,
  };
};

export const EVENT_STATUS_REQUEST = 'EVENT_STATUS_REQUEST';
export const EVENT_STATUS_SUCCESS = 'EVENT_STATUS_SUCCESS';
export const EVENT_STATUS_FAILURE = 'EVENT_STATUS_FAILURE';

const fetchEventStatus = (mpId, eventId, lastUpdated) => {
  return {
    [FETCH_API]: {
      types: [EVENT_STATUS_REQUEST, EVENT_STATUS_SUCCESS, EVENT_STATUS_FAILURE],
      endpoint:
        `event-status/?mpId=${mpId}&eventId=${eventId}` +
        `&lastUpdated=${lastUpdated}&apiKey=` +
        ``,
      method: 'GET',
    },
  };
};

export const loadEventStatus = (mpId, eventId, lastUpdated) => {
  return dispatch => {
    return dispatch(fetchEventStatus(mpId, eventId, lastUpdated));
  };
};

export const SIMULCAST_STATUS_REQUEST = 'SIMULCAST_STATUS_REQUEST';
export const SIMULCAST_STATUS_SUCCESS = 'SIMULCAST_STATUS_SUCCESS';
export const SIMULCAST_STATUS_FAILURE = 'SIMULCAST_STATUS_FAILURE';

const fetchSimulcastStatus = mpId => {
  return {
    [FETCH_API]: {
      types: [
        SIMULCAST_STATUS_REQUEST,
        SIMULCAST_STATUS_SUCCESS,
        SIMULCAST_STATUS_FAILURE,
      ],
      endpoint: `simulcast-status/?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSimulcastStatus = mpId => {
  return dispatch => {
    return dispatch(fetchSimulcastStatus(mpId));
  };
};

export const RECALLS_REQUEST = 'RECALLS_REQUEST';
export const RECALLS_SUCCESS = 'RECALLS_SUCCESS';
export const RECALLS_FAILURE = 'RECALLS_FAILURE';

const fetchRecalls = (year, make, model) => {
  return {
    [FETCH_API]: {
      types: [RECALLS_REQUEST, RECALLS_SUCCESS, RECALLS_FAILURE],
      endpoint: `recall/_search`,
      method: 'POST',
      body: JSON.stringify({
        filter: {
          bool: {
            must: [
              {
                term: {
                  yEARTXT: year,
                },
              },
              {
                term: {
                  mAKETXT: make.toLowerCase(),
                },
              },
              {
                term: {
                  mODELTXT: model.toLowerCase(),
                },
              },
            ],
          },
        },
        size: 10,
        sort: [
          {
            _score: 'desc',
          },
        ],
      }),
      recalls: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRecalls = (year, make, model) => {
  return dispatch => {
    return dispatch(fetchRecalls(year, make, model));
  };
};

export const RESET_RECALLS = 'RESET_RECALLS';

export const resetRecalls = () => {
  return {
    type: RESET_RECALLS,
  };
};

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export const resetErrorMessage = () => {
  return {
    type: RESET_ERROR_MESSAGE,
  };
};

export const ADD_WISHLIST_ITEM_REQUEST = ' ADD_WISHLIST_ITEM_REQUEST';
export const ADD_WISHLIST_ITEM_SUCCESS = 'ADD_WISHLIST_SUCCESS';
export const ADD_WISHLIST_ITEM_FAILURE = 'ADD_WISHLIST_FAILURE';

const fetchAddWishlistItem = (mpId, name, value) => {
  return {
    [FETCH_API]: {
      types: [
        ADD_WISHLIST_ITEM_REQUEST,
        ADD_WISHLIST_ITEM_SUCCESS,
        ADD_WISHLIST_ITEM_FAILURE,
      ],
      endpoint:
        `wishlist-add` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&name=${name}` +
        `&value=${value}`,
      method: 'GET',
    },
  };
};

export const loadAddWishlistItem = (mpId, name, value) => {
  return dispatch => {
    return dispatch(fetchAddWishlistItem(mpId, name, value));
  };
};

export const REMOVE_WISHLIST_ITEM_REQUEST = 'REMOVE_WISHLIST_ITEM_REQUEST';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_FAILURE = 'REMOVE_WISHLIST_ITEM_FAILURE';

const fetchRemoveWishlistItem = (mpId, wishlistId) => {
  return {
    [FETCH_API]: {
      types: [
        REMOVE_WISHLIST_ITEM_REQUEST,
        REMOVE_WISHLIST_ITEM_SUCCESS,
        REMOVE_WISHLIST_ITEM_FAILURE,
      ],
      endpoint:
        `wishlist-remove` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&wishlistId=${wishlistId}`,
      method: 'GET',
    },
  };
};

export const loadRemoveWishlistItem = (mpId, wishlistId) => {
  return dispatch => {
    return dispatch(fetchRemoveWishlistItem(mpId, wishlistId));
  };
};

export const UPDATE_WISHLIST_ITEM_REQUEST = 'UPDATE_WISHLIST_ITEM_REQUEST';
export const UPDATE_WISHLIST_ITEM_SUCCESS = 'UPDATE_WISHLIST_ITEM_SUCCESS';
export const UPDATE_WISHLIST_ITEM_FAILURE = 'UPDATE_WISHLIST_ITEM_FAILURE';

const fetchUpdateWishlistItem = (mpId, wishlistId, name, value) => {
  return {
    [FETCH_API]: {
      types: [
        UPDATE_WISHLIST_ITEM_REQUEST,
        UPDATE_WISHLIST_ITEM_SUCCESS,
        UPDATE_WISHLIST_ITEM_FAILURE,
      ],
      method: 'GET',
      endpoint:
        `wishlist-update` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&wishlistId=${wishlistId}` +
        `&name=${name}` +
        `&value=${value}`,
    },
  };
};

export const loadUpdateWishlistItem = (mpId, wishlistId, name, value) => {
  return dispatch => {
    return dispatch(fetchUpdateWishlistItem(mpId, wishlistId, name, value));
  };
};

export const WISHLIST_ITEMS_REQUEST = 'WISHLIST_ITEMS_REQUEST';
export const WISHLIST_ITEMS_SUCCESS = 'WISHLIST_ITEMS_SUCCESS';
export const WISHLIST_ITEMS_FAILURE = 'WISHLIST_ITEMS_FAILURE';

const fetchWishlistItems = mpId => {
  return {
    [FETCH_API]: {
      types: [
        WISHLIST_ITEMS_REQUEST,
        WISHLIST_ITEMS_SUCCESS,
        WISHLIST_ITEMS_FAILURE,
      ],
      endpoint: `wishlist-items?apiKey=&mpId=${mpId}`,
      method: 'GET',
    },
  };
};

export const loadWishlistItems = mpId => {
  return dispatch => {
    return dispatch(fetchWishlistItems(mpId));
  };
};

export const PRIVATE_LOT_REQUEST = 'PRIVATE_LOT_REQUEST';
export const PRIVATE_LOT_SUCCESS = 'PRIVATE_LOT_SUCCESS';
export const PRIVATE_LOT_FAILURE = 'PRIVATE_LOT_FAILURE';

const getPrivateLotEvents = mpId => {
  return {
    [FETCH_API]: {
      types: [PRIVATE_LOT_REQUEST, PRIVATE_LOT_SUCCESS, PRIVATE_LOT_FAILURE],
      endpoint: `user-event-list?apiKey=&mpId=${mpId}&accountId=0&eventTypeId=6&eventSubTypeId=0`,
      method: 'GET',
    },
  };
};

export const loadPrivateLotEvents = mpId => {
  return dispatch => {
    return dispatch(getPrivateLotEvents(mpId));
  };
};

export const HYPER_LOT_REQUEST = 'HYPER_LOT_REQUEST';
export const HYPER_LOT_SUCCESS = 'HYPER_LOT_SUCCESS';
export const HYPER_LOT_FAILURE = 'HYPER_LOT_FAILURE';

const getHyperLotEvents = mpId => {
  return {
    [FETCH_API]: {
      types: [HYPER_LOT_REQUEST, HYPER_LOT_SUCCESS, HYPER_LOT_FAILURE],
      endpoint: `user-event-list?apiKey=&mpId=${mpId}&accountId=0&eventTypeId=3&eventSubTypeId=5`,
      method: 'GET',
    },
  };
};

export const loadHyperLotEvents = mpId => {
  return dispatch => {
    return dispatch(getHyperLotEvents(mpId));
  };
};

export const EVENTS_SELLER_REQUEST = 'EVENTS_SELLER_REQUEST';
export const EVENTS_SELLER_SUCCESS = 'EVENTS_SELLER_SUCCESS';
export const EVENTS_SELLER_FAILURE = 'EVENTS_SELLER_FAILURE';

const getEventEvents = mpId => {
  return {
    [FETCH_API]: {
      types: [
        EVENTS_SELLER_REQUEST,
        EVENTS_SELLER_SUCCESS,
        EVENTS_SELLER_FAILURE,
      ],
      endpoint: `user-event-list?mpId=${mpId}&apiKey=&mpId=319&accountId=0&eventTypeId=3&eventSubTypeId=5`,
      method: 'GET',
    },
  };
};

export const loadEventEvents = mpId => {
  return dispatch => {
    return dispatch(getEventEvents(mpId));
  };
};

// ALERTS
export const USER_ALERTS_REQUEST = 'USER_ALERTS_REQUEST';
export const USER_ALERTS_SUCCESS = 'USER_ALERTS_SUCCESS';
export const USER_ALERTS_FAILURE = 'USER_ALERTS_FAILURE';

const fetchUserAlert = mpId => {
  return {
    [FETCH_API]: {
      types: [USER_ALERTS_REQUEST, USER_ALERTS_SUCCESS, USER_ALERTS_FAILURE],
      endpoint: `alert-list?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadUserAlerts = mpId => {
  return dispatch => {
    return dispatch(fetchUserAlert(mpId));
  };
};

export const USER_ALERTS_UPDATE_REQUEST = 'USER_ALERTS_UPDATE_REQUEST';
export const USER_ALERTS_UPDATE_SUCCESS = 'USER_ALERTS_UPDATE_SUCCESS';
export const USER_ALERTS_UPDATE_FAILURE = 'USER_ALERTS_UPDATE_FAILURE';

const fetchAlertUpdate = (
  mpId,
  deviceTypeId,
  deviceAddress,
  alertTypeId,
  alertId,
  statusId
) => {
  return {
    [FETCH_API]: {
      types: [
        USER_ALERTS_UPDATE_REQUEST,
        USER_ALERTS_UPDATE_SUCCESS,
        USER_ALERTS_UPDATE_FAILURE,
      ],
      endpoint:
        `${getConfig('apiRoot')}` +
        `alert-update` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&deviceTypeId=${deviceTypeId}` +
        `&deviceAddress=${deviceAddress}` +
        `&alertTypeId=${alertTypeId}` +
        `&alertId=${alertId}` +
        `&statusId=${statusId}`,
      method: 'GET',
    },
  };
};

export const loadAlertUpdate = (
  mpId,
  deviceTypeId,
  deviceAddress,
  alertTypeId,
  alertId,
  statusId
) => {
  return dispatch => {
    return dispatch(
      fetchAlertUpdate(
        mpId,
        deviceTypeId,
        deviceAddress,
        alertTypeId,
        alertId,
        statusId
      )
    );
  };
};

export const USER_ALERTS_ADD_REQUEST = 'USER_ALERTS_ADD_REQUEST';
export const USER_ALERTS_ADD_SUCCESS = 'USER_ALERTS_ADD_SUCCESS';
export const USER_ALERTS_ADD_FAILURE = 'USER_ALERTS_ADD_FAILURE';

const fetchAlertAdd = (mpId, deviceTypeId, deviceAddress, alertTypeId) => {
  return {
    [FETCH_API]: {
      types: [
        USER_ALERTS_ADD_REQUEST,
        USER_ALERTS_ADD_SUCCESS,
        USER_ALERTS_ADD_FAILURE,
      ],
      endpoint:
        `${getConfig('apiRoot')}` +
        `alert-add` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&deviceTypeId=${deviceTypeId}` +
        `&deviceAddress=${deviceAddress}` +
        `&alertTypeIds=${alertTypeId}`,
      method: 'GET',
    },
  };
};

export const loadAlertAdd = (
  mpId,
  deviceTypeId,
  deviceAddress,
  alertTypeId
) => {
  return dispatch => {
    return dispatch(
      fetchAlertAdd(mpId, deviceTypeId, deviceAddress, alertTypeId)
    );
  };
};

export const USER_ALERTS_REMOVE_REQUEST = 'USER_ALERTS_REMOVE_REQUEST';
export const USER_ALERTS_REMOVE_SUCCESS = 'USER_ALERTS_REMOVE_SUCCESS';
export const USER_ALERTS_REMOVE_FAILURE = 'USER_ALERTS_REMOVE_FAILURE';

const fetchAlertRemove = (mpId, alertId) => {
  return {
    [FETCH_API]: {
      types: [
        USER_ALERTS_REMOVE_REQUEST,
        USER_ALERTS_REMOVE_SUCCESS,
        USER_ALERTS_REMOVE_FAILURE,
      ],
      endpoint:
        `${getConfig('apiRoot')}` +
        `alert-remove` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&alertIds=${alertId}`,
      method: 'GET',
    },
  };
};

export const loadAlertRemove = (mpId, alertId) => {
  return dispatch => {
    return dispatch(fetchAlertRemove(mpId, alertId));
  };
};

export const BUYER_LIST_REQUEST = 'BUYER_LIST_REQUEST';
export const BUYER_LIST_SUCCESS = 'BUYER_LIST_SUCCESS';
export const BUYER_LIST_FAILURE = 'BUYER_LIST_FAILURE';

const fetchBuyerList = mpId => {
  return {
    [FETCH_API]: {
      types: [BUYER_LIST_REQUEST, BUYER_LIST_SUCCESS, BUYER_LIST_FAILURE],
      endpoint: `user-group-member-list?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerList = mpId => {
  return dispatch => {
    return dispatch(fetchBuyerList(mpId));
  };
};

export const BUYER_SEARCH_REQUEST = 'BUYER_SEARCH_REQUEST';
export const BUYER_SEARCH_SUCCESS = 'BUYER_SEARCH_SUCCESS';
export const BUYER_SEARCH_FAILURE = 'BUYER_SEARCH_FAILURE';

const fetchBuyerSearch = (mpId, searchNumber) => {
  return {
    [FETCH_API]: {
      types: [BUYER_SEARCH_REQUEST, BUYER_SEARCH_SUCCESS, BUYER_SEARCH_FAILURE],
      endpoint: `external-user-search?mpId=${mpId}&apiKey=&phoneNumber=${searchNumber}`,
      method: 'GET',
    },
  };
};

export const loadBuyerSearch = (mpId, searchNumber) => {
  return dispatch => {
    return dispatch(fetchBuyerSearch(mpId, searchNumber));
  };
};

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

const fetchNotifications = mpId => {
  return {
    [FETCH_API]: {
      types: [
        NOTIFICATIONS_REQUEST,
        NOTIFICATIONS_SUCCESS,
        NOTIFICATIONS_FAILURE,
      ],
      endpoint: `notification-list?mpId=${mpId}&apiKey=&deviceTypeId=6`,
      method: 'GET',
    },
  };
};
export const loadNotifications = mpId => {
  return dispatch => {
    return dispatch(fetchNotifications(mpId));
  };
};

export const ALL_ACTIVITY_REQUEST = 'ALL_ACTIVITY_REQUEST';
export const ALL_ACTIVITY_SUCCESS = 'ALL_ACTIVITY_SUCCESS';
export const ALL_ACTIVITY_FAILURE = 'ALL_ACTIVITY_FAILURE';

const fetchAllAcitivty = mpId => {
  return {
    [FETCH_API]: {
      types: [ALL_ACTIVITY_REQUEST, ALL_ACTIVITY_SUCCESS, ALL_ACTIVITY_FAILURE],
      endpoint: `myactivity?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};
export const loadAllActivity = mpId => {
  return dispatch => {
    return dispatch(fetchAllAcitivty(mpId));
  };
};

export const SELL_NOW_REQUEST = 'SELL_NOW_REQUEST';
export const SELL_NOW_SUCCESS = 'SELL_NOW_SUCCESS';
export const SELL_NOW_FAILURE = 'SELL_NOW_FAILURE';

const fetchSellNow = (mpId, itemId, reduceReserve) => {
  return {
    [FETCH_API]: {
      types: [SELL_NOW_REQUEST, SELL_NOW_SUCCESS, SELL_NOW_FAILURE],
      endpoint:
        `item-update` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&sellNow=true` +
        `&reduceReserve=${reduceReserve}`,
      method: 'GET',
    },
  };
};
export const loadSellNow = (mpId, itemId, reduceReserve) => {
  return dispatch => {
    return dispatch(fetchSellNow(mpId, itemId, reduceReserve));
  };
};

export const LIST_ITEM_REQUEST = 'LIST_ITEM_REQUEST';
export const LIST_ITEM_SUCCESS = 'LIST_ITEM_SUCCESS';
export const LIST_ITEM_FAILURE = 'LIST_ITEM_FAILURE';

const fetchListItem = (mpId, itemId, eventId, rerunRequest) => {
  return {
    [FETCH_API]: {
      types: [LIST_ITEM_REQUEST, LIST_ITEM_SUCCESS, LIST_ITEM_FAILURE],
      endpoint:
        `item-update` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&eventId=${eventId}` +
        `&RerunRequest=${rerunRequest}` +
        `&statusId=1` +
        `&status2Id=0`,
    },
  };
};

export const loadListItem = (mpId, itemId, eventId, rerunRequest) => {
  return dispatch => {
    return dispatch(fetchListItem(mpId, itemId, eventId, rerunRequest));
  };
};

export const UNLIST_REQUEST = 'UNLIST_REQUEST';
export const UNLIST_SUCCESS = 'UNLIST_SUCCESS';
export const UNLIST_FAILURE = 'UNLIST_FAILURE';

const fetchUnlistItem = (mpId, itemId) => {
  return {
    [FETCH_API]: {
      types: [UNLIST_REQUEST, UNLIST_SUCCESS, UNLIST_FAILURE],
      endpoint:
        `item-update` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&statusId=8` +
        `&eventId=0`,

      method: 'GET',
    },
  };
};
export const loadUnlistItem = (mpId, itemId) => {
  return dispatch => {
    return dispatch(fetchUnlistItem(mpId, itemId));
  };
};

export const TRANSPORTATION_TYPES_REQUEST = 'TRANSPORTATION_TYPES_REQUEST';
export const TRANSPORTATION_TYPES_SUCCESS = 'TRANSPORTATION_TYPES_SUCCESS';
export const TRANSPORTATION_TYPES_FAILURE = 'TRANSPORTATION_TYPES_FAILURE';

const fetchTransportationTypes = (mpId, itemId, accountId) => {
  return {
    [FETCH_API]: {
      types: [
        TRANSPORTATION_TYPES_REQUEST,
        TRANSPORTATION_TYPES_SUCCESS,
        TRANSPORTATION_TYPES_FAILURE,
      ],
      endpoint:
        `transportation-estimate` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&accountId=${accountId}`,
      method: 'GET',
    },
  };
};
export const loadTransportationTypes = (mpId, itemId, accountId) => {
  return dispatch => {
    return dispatch(fetchTransportationTypes(mpId, itemId, accountId));
  };
};

export const BUYER_INVITE_LIST_REQUEST = 'BUYER_INVITE_LIST_REQUEST';
export const BUYER_INVITE_LIST_SUCCESS = 'BUYER_INVITE_LIST_SUCCESS';
export const BUYER_INVITE_LIST_FAILURE = 'BUYER_INVITE_LIST_FAILURE';

const buyerInviteListRequest = () => {
  return {
    type: BUYER_INVITE_LIST_SUCCESS,
  };
};

export const loadBuyerInviteListRequest = () => {
  return dispatch => {
    return dispatch(buyerInviteListRequest());
  };
};

export const BUYER_INVITE_ADD_REQUEST = 'BUYER_INVITE_ADD_REQUEST';
export const BUYER_INVITE_ADD_SUCCESS = 'BUYER_INVITE_ADD_SUCCESS';
export const BUYER_INVITE_ADD_FAILURE = 'BUYER_INVITE_ADD_FAILURE';

const buyerInviteAddRequest = addProps => {
  return {
    type: BUYER_INVITE_ADD_SUCCESS,
    addProps,
  };
};

export const loadBuyerInviteAddRequest = addProps => {
  return dispatch => {
    return dispatch(buyerInviteAddRequest(addProps));
  };
};

export const BUYER_INVITE_REMOVE_REQUEST = 'BUYER_INVITE_REMOVE_REQUEST';
export const BUYER_INVITE_REMOVE_SUCCESS = 'BUYER_INVITE_REMOVE_SUCCESS';
export const BUYER_INVITE_REMOVE_FAILURE = 'BUYER_INVITE_REMOVE_FAILURE';

const buyerInviteRemoveRequest = removeProps => {
  return {
    type: BUYER_INVITE_REMOVE_SUCCESS,
    removeProps,
  };
};

export const loadBuyerInviteRemoveRequest = removeProps => {
  return dispatch => {
    return dispatch(buyerInviteRemoveRequest(removeProps));
  };
};

export const CLEAR_BUYER_INVITES = 'CLEAR_BUYER_INVITES';

const clearBuyerInvites = () => {
  return {
    type: CLEAR_BUYER_INVITES,
  };
};

export const loadClearBuyerInvites = () => {
  return dispatch => {
    return dispatch(clearBuyerInvites());
  };
};

export const SAVE_PRIVATE_LOT_REQUEST = 'SAVE_PRIVATE_LOT_REQUEST';
export const SAVE_PRIVATE_LOT_SUCCESS = 'SAVE_PRIVATE_LOT_SUCCESS';
export const SAVE_PRIVATE_LOT_FAILURE = 'SAVE_PRIVATE_LOT_FAILURE';

const savePrivateLotSuccess = successResponse => {
  return {
    type: SAVE_PRIVATE_LOT_SUCCESS,
    successResponse,
  };
};

export const loadPrivateLotSuccess = successResponse => {
  return dispatch => {
    return dispatch(savePrivateLotSuccess(successResponse));
  };
};

const savePrivateLotFailure = errorResponse => {
  return {
    type: SAVE_PRIVATE_LOT_FAILURE,
    errorResponse,
  };
};

export const loadPrivateLotFailure = errorResponse => {
  return dispatch => {
    return dispatch(savePrivateLotFailure(errorResponse));
  };
};

export const ADD_PRIVATE_LOT_ITEMS_REQUEST = 'ADD_PRIVATE_LOT_ITEMS_REQUEST';
export const ADD_PRIVATE_LOT_ITEMS_SUCCESS = 'ADD_PRIVATE_LOT_ITEMS_SUCCESS';
export const ADD_PRIVATE_LOT_ITEMS_FAILURE = 'ADD_PRIVATE_LOT_ITEMS_FAILURE';

const addPrivateLotItemsSuccess = successResponse => {
  return {
    type: ADD_PRIVATE_LOT_ITEMS_SUCCESS,
    successResponse,
  };
};

export const loadPrivateLotItemsSuccess = successResponse => {
  return dispatch => {
    return dispatch(addPrivateLotItemsSuccess(successResponse));
  };
};

const addPrivateLotItemsFailure = errorResponse => {
  return {
    type: ADD_PRIVATE_LOT_ITEMS_FAILURE,
    errorResponse,
  };
};

export const loadPrivateLotItemsFailure = errorResponse => {
  return dispatch => {
    return dispatch(addPrivateLotItemsFailure(errorResponse));
  };
};

export const ITEM_UPDATE_OFFER_REQUEST = 'ITEM_UPDATE_OFFER_REQUEST';
export const ITEM_UPDATE_OFFER_SUCCESS = 'ITEM_UPDATE_OFFER_SUCCESS';
export const ITEM_UPDATE_OFFER_FAILURE = 'ITEM_UPDATE_OFFER_FAILURE';

const fetchItemUpdateOffer = (mpId, itemId, offerId, statusId, amount) => {
  return {
    [FETCH_API]: {
      types: [
        ITEM_UPDATE_OFFER_REQUEST,
        ITEM_UPDATE_OFFER_SUCCESS,
        ITEM_UPDATE_OFFER_FAILURE,
      ],
      endpoint:
        `item-update-offer` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&itemId=${itemId}` +
        `&offerId=${offerId}` +
        `&statusId=${statusId}` +
        `&amount=${amount}` +
        `&transactionSourceId=2`,
    },
  };
};

export const loadItemUpdateOffer = (
  mpId,
  itemId,
  offerId,
  statusId,
  amount
) => {
  return dispatch => {
    return dispatch(
      fetchItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
    );
  };
};

export const ITEM_UPDATE_PRICING_REQUEST = 'ITEM_UPDATE_PRICING_REQUEST';
export const ITEM_UPDATE_PRICING_SUCCESS = 'ITEM_UPDATE_PRICING_SUCCESS';
export const ITEM_UPDATE_PRICING_FAILURE = 'ITEM_UPDATE_PRICING_FAILURE';

const fetchItemUpdatePricing = (
  mpId,
  itemId,
  startingBid,
  reservePrice,
  outrightPrice
) => {
  return {
    [FETCH_API]: {
      types: [
        ITEM_UPDATE_PRICING_REQUEST,
        ITEM_UPDATE_PRICING_SUCCESS,
        ITEM_UPDATE_PRICING_FAILURE,
      ],
      endpoint:
        `item-update-pricing` +
        `?itemId=${itemId}` +
        `&startingBid=${startingBid}` +
        `&reservePrice=${reservePrice}` +
        `&outrightPrice=${outrightPrice}` +
        `&mpId=${mpId}` +
        `&apiKey=`,
    },
  };
};

export const loadItemUpdatePricing = (
  mpId,
  itemId,
  startingBid,
  reservePrice,
  outrightPrice
) => {
  return dispatch => {
    return dispatch(
      fetchItemUpdatePricing(
        mpId,
        itemId,
        startingBid,
        reservePrice,
        outrightPrice
      )
    );
  };
};

export const ITEM_UPDATE_SELLER_DISCLOSURE_REQUEST =
  'ITEM_UPDATE_SELLER_DISCLOSURE_REQUEST';
export const ITEM_UPDATE_SELLER_DISCLOSURE_SUCCESS =
  'ITEM_UPDATE_SELLER_DISCLOSURE_SUCCESS';
export const ITEM_UPDATE_SELLER_DISCLOSURE_FAILURE =
  'ITEM_UPDATE_SELLER_DISCLOSURE_FAILURE';

const fetchItemUpdateSellerDisclosure = (mpId, itemId, sellerDisclosure) => {
  return {
    [FETCH_API]: {
      types: [
        ITEM_UPDATE_SELLER_DISCLOSURE_REQUEST,
        ITEM_UPDATE_SELLER_DISCLOSURE_SUCCESS,
        ITEM_UPDATE_SELLER_DISCLOSURE_FAILURE,
      ],
      endpoint:
        `item-update` +
        `?itemId=${itemId}` +
        `&sellerAnnouncement=${sellerDisclosure}` +
        `&mpId=${mpId}` +
        `&apiKey=`,
    },
  };
};

export const loadItemUpdateSellerDisclosure = (
  mpId,
  itemId,
  sellerDisclosure
) => {
  return dispatch => {
    return dispatch(
      fetchItemUpdateSellerDisclosure(mpId, itemId, sellerDisclosure)
    );
  };
};

export const REMOVE_ITEM_REQUEST = 'REMOVE_ITEM_REQUEST';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAILURE = 'REMOVE_ITEM_FAILURE';

const fetchRemoveItem = (mpId, itemId) => {
  return {
    [FETCH_API]: {
      types: [
        ITEM_UPDATE_SELLER_DISCLOSURE_REQUEST,
        ITEM_UPDATE_SELLER_DISCLOSURE_SUCCESS,
        ITEM_UPDATE_SELLER_DISCLOSURE_FAILURE,
      ],
      endpoint:
        `item-update` +
        `?itemId=${itemId}` +
        `&statusId=4` +
        `&status2Id=103` +
        `&mpId=${mpId}` +
        `&apiKey=`,
    },
  };
};

export const loadRemoveItem = (mpId, itemId) => {
  return dispatch => {
    return dispatch(fetchRemoveItem(mpId, itemId));
  };
};

export const RETAIL_VIEW_ON = 'RETAIL_VIEW_ON';

export const retailViewOn = () => {
  return {
    type: RETAIL_VIEW_ON,
  };
};

export const RETAIL_VIEW_OFF = 'RETAIL_VIEW_OFF';

export const retailViewOff = () => {
  return {
    type: RETAIL_VIEW_OFF,
  };
};

export const CARFAX_LOGIN_CHECK_REQUEST = 'CARFAX_LOGIN_CHECK_REQUEST';
export const CARFAX_LOGIN_CHECK_SUCCESS = 'CARFAX_LOGIN_CHECK_SUCCESS';
export const CARFAX_LOGIN_CHECK_FAILURE = 'CARFAX_LOGIN_CHECK_FAILURE';

const fetchCarfaxLoginCheck = mpId => {
  return {
    [FETCH_API]: {
      types: [
        CARFAX_LOGIN_CHECK_REQUEST,
        CARFAX_LOGIN_CHECK_SUCCESS,
        CARFAX_LOGIN_CHECK_FAILURE,
      ],
      endpoint:
        `/carfax/v2/user_logged_in_check` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&deviceId=54321`,
    },
  };
};

export const loadCarfaxLoginCheck = mpId => {
  return dispatch => {
    return dispatch(fetchCarfaxLoginCheck(mpId));
  };
};

export const CARFAX_LOGIN_REQUEST = 'CARFAX_LOGIN_REQUEST';
export const CARFAX_LOGIN_SUCCESS = 'CARFAX_LOGIN_SUCCESS';
export const CARFAX_LOGIN_FAILURE = 'CARFAX_LOGIN_FAILURE';

const fetchCarfaxLogin = (mpId, code, redirectUri) => {
  return {
    [FETCH_API]: {
      types: [CARFAX_LOGIN_REQUEST, CARFAX_LOGIN_SUCCESS, CARFAX_LOGIN_FAILURE],
      endpoint:
        `carfax/v2/login` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&code=${code}` +
        `&redirect_uri=${redirectUri}` +
        `&deviceId=54321`,
    },
  };
};

export const loadCarfaxLogin = (mpId, code, redirectUri) => {
  return dispatch => {
    return dispatch(fetchCarfaxLogin(mpId, code, redirectUri));
  };
};

export const CARFAX_LOGOUT_REQUEST = 'CARFAX_LOGOUT_REQUEST';
export const CARFAX_LOGOUT_SUCCESS = 'CARFAX_LOGOUT_SUCCESS';
export const CARFAX_LOGOUT_FAILURE = 'CARFAX_LOGOUT_FAILURE';

const fetchCarfaxLogout = mpId => {
  return {
    [FETCH_API]: {
      types: [
        CARFAX_LOGOUT_REQUEST,
        CARFAX_LOGOUT_SUCCESS,
        CARFAX_LOGOUT_FAILURE,
      ],
      endpoint:
        `carfax/v2/logout` + `?apiKey=` + `&mpId=${mpId}` + `&deviceId=54321`,
    },
  };
};

export const loadCarfaxLogout = mpId => {
  return dispatch => {
    return dispatch(fetchCarfaxLogout(mpId));
  };
};

export const CARFAX_REPORT_REQUEST = 'CARFAX_REPORT_REQUEST';
export const CARFAX_REPORT_SUCCESS = 'CARFAX_REPORT_SUCCESS';
export const CARFAX_REPORT_FAILURE = 'CARFAX_REPORT_FAILURE';

const fetchCarfaxReport = (mpId, vin) => {
  return {
    [FETCH_API]: {
      types: [
        CARFAX_REPORT_REQUEST,
        CARFAX_REPORT_SUCCESS,
        CARFAX_REPORT_FAILURE,
      ],
      endpoint:
        `carfax/v2/url` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&vin=${vin}` +
        `&deviceId=54321`,
    },
  };
};

export const loadCarfaxReport = (mpId, vin) => {
  return dispatch => {
    return dispatch(fetchCarfaxReport(mpId, vin));
  };
};

export const CARFAX_REPORT_RESET = 'CARFAX_REPORT_RESET';

export const resetCarfaxReport = () => {
  return {
    type: CARFAX_REPORT_RESET,
  };
};

export const CARFAX_CODE_SUCCESS = 'CARFAX_CODE_SUCCESS';

export const loadCarfaxCode = code => {
  return {
    type: CARFAX_CODE_SUCCESS,
    response: code,
  };
};

export const RESET_CARFAX_CODE = 'RESET_CARFAX_CODE';

export const resetCarfaxCode = () => {
  return {
    type: RESET_CARFAX_CODE,
  };
};

export const MMR_REQUEST = 'MMR_REQUEST';
export const MMR_SUCCESS = 'MMR_SUCCESS';
export const MMR_FAILURE = 'MMR_FAILURE';

const fetchMMR = (mpId, vin, mileage, mmrId) => {
  return {
    [FETCH_API]: {
      types: [MMR_REQUEST, MMR_SUCCESS, MMR_FAILURE],
      endpoint:
        `book-values` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&features=MMRPricing,MMRBulkBook,MMRTransactions` +
        `&vin=${vin}` +
        `&mileage=${mileage}`,
    },
  };
};

export const loadMMR = (mpId, vin, mileage, mmrId) => {
  return dispatch => dispatch(fetchMMR(mpId, vin, mileage, mmrId));
};

export const RESET_MMR = 'RESET_MMR';

export const resetMMR = () => {
  return {
    type: RESET_MMR,
  };
};

export const WATCHLIST_USER_ITEMS_REQUEST = 'WATCHLIST_USER_ITEMS_REQUEST';
export const WATCHLIST_USER_ITEMS_SUCCESS = 'WATCHLIST_USER_ITEMS_SUCCESS';
export const WATCHLIST_USER_ITEMS_FAILURE = 'WATCHLIST_USER_ITEMS_FAILURE';

const fetchWatchlistUserItems = mpId => {
  return {
    [FETCH_API]: {
      types: [
        WATCHLIST_USER_ITEMS_REQUEST,
        WATCHLIST_USER_ITEMS_SUCCESS,
        WATCHLIST_USER_ITEMS_FAILURE,
      ],
      endpoint: `watchlist-user-items` + `?apiKey=` + `&mpId=${mpId}`,

      method: 'GET',
    },
  };
};

export const loadWatchlistUserItems = mpId => {
  return dispatch => {
    return dispatch(fetchWatchlistUserItems(mpId));
  };
};

export const WATCHLIST_OBJECT_REQUEST = 'WATCHLIST_OBJECT_REQUEST';
export const WATCHLIST_OBJECT_SUCCESS = 'WATCHLIST_OBJECT_SUCCESS';
export const WATCHLIST_OBJECT_FAILURE = 'WATCHLIST_OBJECT_FAILURE';

const fetchWatchlistObject = mpId => {
  return {
    [FETCH_API]: {
      types: [
        WATCHLIST_OBJECT_REQUEST,
        WATCHLIST_OBJECT_SUCCESS,
        WATCHLIST_OBJECT_FAILURE,
      ],
      endpoint: `watchlist-user-items` + `?apiKey=` + `&mpId=${mpId}`,

      method: 'GET',
    },
  };
};

export const loadWatchlistObject = mpId => {
  return dispatch => {
    return dispatch(fetchWatchlistObject(mpId));
  };
};

export const GEOCODE_REQUEST = 'GEOCODE_REQUEST';
export const GEOCODE_SUCCESS = 'GEOCODE_SUCCESS';
export const GEOCODE_FAILURE = 'GEOCODE_FAILURE';

const fetchGeocode = (mpId, address) => {
  // address can be zipcode, or 'city state'
  return {
    [FETCH_API]: {
      types: [GEOCODE_REQUEST, GEOCODE_SUCCESS, GEOCODE_FAILURE],
      endpoint: `get-geocode?apiKey=&mpId=${mpId}&address=${address}`,

      method: 'GET',
    },
  };
};

export const loadGeocode = (mpId, address) => {
  return dispatch => {
    return dispatch(fetchGeocode(mpId, address));
  };
};

export const USER_ITEMS_REQUEST = 'USER_ITEMS_REQUEST';
export const USER_ITEMS_SUCCESS = 'USER_ITEMS_SUCCESS';
export const USER_ITEMS_FAILURE = 'USER_ITEMS_FAILURE';

const fetchUserItems = mpId => {
  return {
    [FETCH_API]: {
      types: [USER_ITEMS_REQUEST, USER_ITEMS_SUCCESS, USER_ITEMS_FAILURE],
      endpoint: `user-item-list?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadUserItems = mpId => {
  return dispatch => {
    return dispatch(fetchUserItems(mpId));
  };
};

export const USER_SELECTED_LOCATION_SUCCESS = 'USER_SELECTED_LOCATION_SUCCESS';

export const loadUserSelectedLocation = userSelectedLocation => {
  return {
    type: USER_SELECTED_LOCATION_SUCCESS,
    response: userSelectedLocation,
  };
};

export const USER_SELECTED_DISTANCE_SUCCESS = 'USER_SELECTED_DISTANCE_SUCCESS';

export const loadUserSelectedDistance = userSelectedDistance => {
  return {
    type: USER_SELECTED_DISTANCE_SUCCESS,
    response: userSelectedDistance,
  };
};

export const SET_TABLE_REF_SUCCESS = 'SET_TABLE_REF_SUCCESS';

export const setTableRef = tableRef => {
  return {
    type: SET_TABLE_REF_SUCCESS,
    response: tableRef,
  };
};

export const SET_TEMPLATE_SUCCESS = 'SET_TEMPLATE_SUCCESS';

export const setTemplate = template => {
  return {
    type: SET_TEMPLATE_SUCCESS,
    response: template,
  };
};

export const SPIN_CARS_REQUEST = 'SPIN_CARS_REQUEST';
export const SPIN_CARS_SUCCESS = 'SPIN_CARS_SUCCESS';
export const SPIN_CARS_FAILURE = 'SPIN_CARS_FAILURE';

export const fetchSpinCars = () => {
  const authKey =
    'fd8dd919d7c2cda8dd8c4b07f68bbbd058808214cb2d8099d86f9f740e8cc6353a080079a963b8a5d63e8580011fb2eca8a81fafdf76729125d5a93489eb1f22';

  return {
    [FETCH_API]: {
      types: [SPIN_CARS_REQUEST, SPIN_CARS_SUCCESS, SPIN_CARS_FAILURE],
      otherApiRoot: 'https://find-spin-api.spincar.com/',
      endpoint: `provider-api/integratedauctionsolutions/iasmarketplace.com?auth=${authKey}`,
      method: 'GET',
    },
  };
};

export const loadSpinCars = () => {
  return dispatch => dispatch(fetchSpinCars());
};

export const SPIN_CAR_REQUEST = 'SPIN_CAR_REQUEST';
export const SPIN_CAR_SUCCESS = 'SPIN_CAR_SUCCESS';
export const SPIN_CAR_FAILURE = 'SPIN_CAR_FAILURE';

export const fetchSpinCar = vin => {
  const authKey =
    'fd8dd919d7c2cda8dd8c4b07f68bbbd058808214cb2d8099d86f9f740e8cc6353a080079a963b8a5d63e8580011fb2eca8a81fafdf76729125d5a93489eb1f22';

  return {
    [FETCH_API]: {
      types: [SPIN_CAR_REQUEST, SPIN_CAR_SUCCESS, SPIN_CAR_FAILURE],
      otherApiRoot: 'https://find-spin-api.spincar.com/',
      endpoint: `provider-api/integratedauctionsolutions/iasmarketplace.com/${vin}?auth=${authKey}`,
      method: 'GET',
    },
  };
};

export const loadSpinCar = vin => {
  return dispatch => dispatch(fetchSpinCar(vin));
};

// *[TODO]* - 2-20-24 BW - ARI Token -- Investigate and remove if not used
export const ARI_TOKEN_REQUEST = 'ARI_TOKEN_REQUEST';
export const ARI_TOKEN_SUCCESS = 'ARI_TOKEN_SUCCESS';
export const ARI_TOKEN_FAILURE = 'ARI_TOKEN_FAILURE';

export const fetchARIToken = () => {
  return {
    [FETCH_API]: {
      types: [ARI_TOKEN_REQUEST, ARI_TOKEN_SUCCESS, ARI_TOKEN_FAILURE],
      otherApiRoot: 'https://equipment.holman.com',
      endpoint: `/api/ari-token`,
      method: 'GET',
    },
  };
};

export const loadARIToken = () => {
  return dispatch => dispatch(fetchARIToken());
};

export const ARI_TERMS_REQUEST = 'ARI_TERMS_REQUEST';
export const ARI_TERMS_SUCCESS = 'ARI_TERMS_SUCCESS';
export const ARI_TERMS_FAILURE = 'ARI_TERMS_FAILURE';

export const fetchARITerms = () => {
  return {
    [FETCH_API]: {
      types: [ARI_TERMS_REQUEST, ARI_TERMS_SUCCESS, ARI_TERMS_FAILURE],
      endpoint: `holman/terms-and-conditions?apiKey=`,
      method: 'GET',
      headers: {
        Accept: '/',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadARITerms = () => {
  return dispatch => dispatch(fetchARITerms());
};

export const ARI_ADDRESS_VALIDATION_REQUEST = 'ARI_ADDRESS_VALIDATION_REQUEST';
export const ARI_ADDRESS_VALIDATION_SUCCESS = 'ARI_ADDRESS_VALIDATION_SUCCESS';
export const ARI_ADDRESS_VALIDATION_FAILURE = 'ARI_ADDRESS_VALIDATION_FAILURE';

export const fetchARIAddressValidation = zipcode => {
  return {
    [FETCH_API]: {
      types: [
        ARI_ADDRESS_VALIDATION_REQUEST,
        ARI_ADDRESS_VALIDATION_SUCCESS,
        ARI_ADDRESS_VALIDATION_FAILURE,
      ],
      otherApiRoot: 'https://equipment.holman.com',
      endpoint: `/api/address-validation?countryCode=US&postalCode=${zipcode}`,
      method: 'GET',
    },
  };
};

export const loadARIAddressValidation = zipcode => {
  return dispatch => dispatch(fetchARIAddressValidation(zipcode));
};

export const REMOVE_PROXY_BID_REQUEST = 'REMOVE_PROXY_BID_REQUEST';
export const REMOVE_PROXY_BID_SUCCESS = 'REMOVE_PROXY_BID_SUCCESS';
export const REMOVE_PROXY_BID_FAILURE = 'REMOVE_PROXY_BID_FAILURE';

export const fetchRemoveProxyBid = (mpId, offerId, userId, eventId) => {
  return {
    [FETCH_API]: {
      types: [
        REMOVE_PROXY_BID_REQUEST,
        REMOVE_PROXY_BID_SUCCESS,
        REMOVE_PROXY_BID_FAILURE,
      ],
      endpoint:
        `remove-proxy-bid` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&offerId=${offerId}` +
        `&userId=${userId}` +
        `&eventId=${eventId}`,
      method: 'GET',
    },
  };
};

export const loadRemoveProxyBid = (mpId, offerId, userId, eventId) => {
  return dispatch =>
    dispatch(fetchRemoveProxyBid(mpId, offerId, userId, eventId));
};

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

const fetchCategories = mpId => {
  return {
    [FETCH_API]: {
      types: [CATEGORIES_REQUEST, CATEGORIES_SUCCESS, CATEGORIES_FAILURE],
      endpoint: `category-list?mpId=${mpId}&apiKey=`,
    },
  };
};

export const loadCategories = mpId => {
  return dispatch => {
    return dispatch(fetchCategories(mpId));
  };
};

export const ATTRIBUTES_REQUEST = 'ATTRIBUTES_REQUEST';
export const ATTRIBUTES_SUCCESS = 'ATTRIBUTES_SUCCESS';
export const ATTRIBUTES_FAILURE = 'ATTRIBUTES_FAILURE';

const fetchAttributes = mpId => {
  return {
    [FETCH_API]: {
      types: [ATTRIBUTES_REQUEST, ATTRIBUTES_SUCCESS, ATTRIBUTES_FAILURE],
      endpoint: `attribute-list?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadAttributes = mpId => {
  return dispatch => {
    return dispatch(fetchAttributes(mpId));
  };
};

export const FIND_MAX_CARD_HEIGHT = 'FIND_MAX_CARD_HEIGHT';

export const findMaxCardHeight = (height, id) => {
  return {
    type: FIND_MAX_CARD_HEIGHT,
    height,
    id,
  };
};

export const RESET_MAX_CARD_HEIGHT = 'RESET_MAX_CARD_HEIGHT';
export const resetMaxCardHeight = () => {
  return {
    type: RESET_MAX_CARD_HEIGHT,
  };
};

export const UPDATE_BID_MESSAGES_REAL_TIME_SUCCESS =
  'UPDATE_BID_MESSAGES_REAL_TIME_SUCCESS';

export const updateBidMessagesRealTime = message => {
  return {
    type: UPDATE_BID_MESSAGES_REAL_TIME_SUCCESS,
    response: message,
  };
};

export const RESET_BID_MESSAGES_REAL_TIME = 'RESET_BID_MESSAGES_REAL_TIME';

export const resetBidMessagesRealTime = () => {
  return {
    type: RESET_BID_MESSAGES_REAL_TIME,
  };
};

export const UPDATE_BID_MESSAGE_HISTORY_REAL_TIME_SUCCESS =
  'UPDATE_BID_MESSAGE_HISTORY_REAL_TIME_SUCCESS';

export const updateBidMessageHistoryRealTime = message => {
  return {
    type: UPDATE_BID_MESSAGE_HISTORY_REAL_TIME_SUCCESS,
    response: message,
  };
};

export const RESET_BID_MESSAGE_HISTORY_REAL_TIME =
  'RESET_BID_MESSAGE_HISTORY_REAL_TIME';

export const resetBidMessageHistoryRealTime = () => {
  return {
    type: RESET_BID_MESSAGE_HISTORY_REAL_TIME,
  };
};

export const AUTHORIZED_EVENTS_REQUEST = 'AUTHORIZED_EVENTS_REQUEST';
export const AUTHORIZED_EVENTS_SUCCESS = 'AUTHORIZED_EVENTS_SUCCESS';
export const AUTHORIZED_EVENTS_FAILURE = 'AUTHORIZED_EVENTS_FAILURE';

const fetchAuthorizedEvents = mpId => {
  return {
    [FETCH_API]: {
      types: [
        AUTHORIZED_EVENTS_REQUEST,
        AUTHORIZED_EVENTS_SUCCESS,
        AUTHORIZED_EVENTS_FAILURE,
      ],
      endpoint:
        `authorized-event-list/` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&privateEventsOnly=true`,
      method: 'POST',
      body: JSON.stringify({}),
    },
  };
};

export const loadAuthorizedEvents = mpId => {
  return dispatch => {
    return dispatch(fetchAuthorizedEvents(mpId));
  };
};

export const UNAUTHORIZED_EVENT_IDS = 'UNAUTHORIZED_EVENT_IDS';

export const loadUnauthorizedEventIds = (eventIds = []) => {
  return {
    type: UNAUTHORIZED_EVENT_IDS,
    response: eventIds,
  };
};

export const SET_LISTING_STATUS_IDS = 'SET_LISTING_STATUS_IDS';

export const setListingStatusIds = listingStatusIds => {
  if (!listingStatusIds || !listingStatusIds.length) listingStatusIds = [1, 3]; // default

  return {
    type: SET_LISTING_STATUS_IDS,
    response: listingStatusIds,
  };
};

export const BUYER_FEES_REQUEST = 'BUYER_FEES_REQUEST';
export const BUYER_FEES_SUCCESS = 'BUYER_FEES_SUCCESS';
export const BUYER_FEES_FAILURE = 'BUYER_FEES_FAILURE';

const fetchBuyerFees = (mpId, itemId, amount, bidTypeId, userId) => {
  return {
    [FETCH_API]: {
      types: [BUYER_FEES_REQUEST, BUYER_FEES_SUCCESS, BUYER_FEES_FAILURE],
      endpoint:
        `${getConfig('apiRoot')}` +
        `item-fees` +
        `?mpId=${mpId}` +
        `&apiKey=` +
        `&itemId=${itemId}` +
        `&amount=${amount}` +
        `&bidTypeId=${bidTypeId}` +
        `${userId ? '&userId=' + userId : ''}`,
      method: 'GET',
    },
  };
};

export const loadBuyerFees = (mpId, itemId, amount, bidTypeId, userId) => {
  return dispatch => {
    return dispatch(fetchBuyerFees(mpId, itemId, amount, bidTypeId, userId));
  };
};

// elasticsearch searches
export const WISHLIST_SEARCH_REQUEST = 'WISHLIST_SEARCH_REQUEST';
export const WISHLIST_SEARCH_SUCCESS = 'WISHLIST_SEARCH_SUCCESS';
export const WISHLIST_SEARCH_FAILURE = 'WISHLIST_SEARCH_FAILURE';

const fetchWishlistSearch = (
  statusIds,
  unauthorizedEventIds = [],
  post_filter = { match_all: {} },
  filter = []
) => {
  return {
    [FETCH_API]: {
      types: [
        WISHLIST_SEARCH_REQUEST,
        WISHLIST_SEARCH_SUCCESS,
        WISHLIST_SEARCH_FAILURE,
      ],
      endpoint: `_search/?size=0`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ terms: { listingStatusId: statusIds } }],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
            filter,
          },
        },
        post_filter,
        size: 0,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadWishlistSearch = (
  statusIds,
  unauthorizedEventIds,
  postFilter,
  filter
) => {
  return dispatch => {
    return dispatch(
      fetchWishlistSearch(statusIds, unauthorizedEventIds, postFilter, filter)
    );
  };
};

export const ITEM_COUNT_REQUEST = 'ITEM_COUNT_REQUEST';
export const ITEM_COUNT_SUCCESS = 'ITEM_COUNT_SUCCESS';
export const ITEM_COUNT_FAILURE = 'ITEM_COUNT_FAILURE';

const fetchItemCount = (statusIds, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [ITEM_COUNT_REQUEST, ITEM_COUNT_SUCCESS, ITEM_COUNT_FAILURE],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ terms: { listingStatusId: statusIds } }],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadItemCount = (statusIds, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchItemCount(statusIds, unauthorizedEventIds));
  };
};

export const BUY_NOW_COUNT_REQUEST = 'BUY_NOW_COUNT_REQUEST';
export const BUY_NOW_COUNT_SUCCESS = 'BUY_NOW_COUNT_SUCCESS';
export const BUY_NOW_COUNT_FAILURE = 'BUY_NOW_COUNT_FAILURE';

const fetchBuyNowCount = (statusIds = [], unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        BUY_NOW_COUNT_REQUEST,
        BUY_NOW_COUNT_SUCCESS,
        BUY_NOW_COUNT_FAILURE,
      ],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { range: { outrightPrice: { gt: 0 } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadBuyNowCount = (statusIds = [], unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchBuyNowCount(statusIds, unauthorizedEventIds));
  };
};

export const SELLING_NOW_COUNT_REQUEST = 'SELLING_NOW_COUNT_REQUEST';
export const SELLING_NOW_COUNT_SUCCESS = 'SELLING_NOW_COUNT_SUCCESS';
export const SELLING_NOW_COUNT_FAILURE = 'SELLING_NOW_COUNT_FAILURE';

const fetchSellingNowCount = (statusIds = [], unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        SELLING_NOW_COUNT_REQUEST,
        SELLING_NOW_COUNT_SUCCESS,
        SELLING_NOW_COUNT_FAILURE,
      ],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { range: { outrightPrice: { gt: 0 } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadSellingNowCount = (statusIds, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchSellingNowCount(statusIds, unauthorizedEventIds));
  };
};

export const TRADE_COUNT_REQUEST = 'TRADE_COUNT_REQUEST';
export const TRADE_COUNT_SUCCESS = 'TRADE_COUNT_SUCCESS';
export const TRADE_COUNT_FAILURE = 'TRADE_COUNT_FAILURE';

const fetchTradeCount = (statusIds = [], unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [TRADE_COUNT_REQUEST, TRADE_COUNT_SUCCESS, TRADE_COUNT_FAILURE],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { term: { eventFeatures: '42' } },
              { terms: { listingStatusId: statusIds } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadTradeCount = (statusIds, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchTradeCount(statusIds, unauthorizedEventIds));
  };
};

export const RETAIL_COUNT_REQUEST = 'RETAIL_COUNT_REQUEST';
export const RETAIL_COUNT_SUCCESS = 'RETAIL_COUNT_SUCCESS';
export const RETAIL_COUNT_FAILURE = 'RETAIL_COUNT_FAILURE';

const fetchRetailCount = (statusIds = [], unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [RETAIL_COUNT_REQUEST, RETAIL_COUNT_SUCCESS, RETAIL_COUNT_FAILURE],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { term: { eventFeatures: '41' } },
              { terms: { listingStatusId: statusIds } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRetailCount = (statusIds, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRetailCount(statusIds, unauthorizedEventIds));
  };
};

export const MARKETPLACE_COUNT_REQUEST = 'MARKETPLACE_COUNT_REQUEST';
export const MARKETPLACE_COUNT_SUCCESS = 'MARKETPLACE_COUNT_SUCCESS';
export const MARKETPLACE_COUNT_FAILURE = 'MARKETPLACE_COUNT_FAILURE';

const fetchMarketplaceCount = (
  marketplaceId,
  statusIds = [],
  unauthorizedEventIds = []
) => {
  return {
    [FETCH_API]: {
      types: [
        MARKETPLACE_COUNT_REQUEST,
        MARKETPLACE_COUNT_SUCCESS,
        MARKETPLACE_COUNT_FAILURE,
      ],
      endpoint: `_count`,
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { term: { marketplaceId: marketplaceId } },
              { terms: { listingStatusId: statusIds } },
            ],
            must_not: [
              { term: { eventFeatures: 41 } },
              { terms: { eventIds: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadMarketplaceCount = (
  marketplaceId,
  statusIds,
  unauthorizedEventIds
) => {
  return dispatch => {
    return dispatch(
      fetchMarketplaceCount(marketplaceId, statusIds, unauthorizedEventIds)
    );
  };
};

export const ELASTIC_SEARCH_DISTANCE_REQUEST =
  'ELASTIC_SEARCH_DISTANCE_REQUEST';
export const ELASTIC_SEARCH_DISTANCE_SUCCESS =
  'ELASTIC_SEARCH_DISTANCE_SUCCESS';
export const ELASTIC_SEARCH_DISTANCE_FAILURE =
  'ELASTIC_SEARCH_DISTANCE_FAILURE';

const fetchElasticSearchDistance = (itemId, userLat, userLon, units) => {
  return {
    [FETCH_API]: {
      types: [
        ELASTIC_SEARCH_DISTANCE_REQUEST,
        ELASTIC_SEARCH_DISTANCE_SUCCESS,
        ELASTIC_SEARCH_DISTANCE_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ term: { id: itemId } }],
          },
        },
        _source: false,
        script_fields: {
          distance: {
            script: {
              source:
                "doc['coordinates'].planeDistance(params.lat,params.lon) * params.multiplier",
              params: {
                lat: userLat,
                lon: userLon,
                multiplier: units === 'km' ? 0.001 : 0.00062137,
              },
            },
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadElasticSearchDistance = (itemId, userLat, userLon, units) => {
  return dispatch => {
    return dispatch(
      fetchElasticSearchDistance(itemId, userLat, userLon, units)
    );
  };
};

export const WATCHLIST_ES_REQUEST = 'WATCHLIST_ES_REQUEST';
export const WATCHLIST_ES_SUCCESS = 'WATCHLIST_ES_SUCCESS';
export const WATCHLIST_ES_FAILURE = 'WATCHLIST_ES_FAILURE';

const fetchWatchlistES = (ids = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [WATCHLIST_ES_REQUEST, WATCHLIST_ES_SUCCESS, WATCHLIST_ES_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ terms: { id: uniq(ids) } }],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
            should: [
              {
                // use this to maintain the order of ids
                query_string: {
                  default_field: 'id',
                  query: uniq(ids)
                    .reverse()
                    .map((id, index) => `${id}^${index + 1}`)
                    .join(' OR '),
                },
              },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadWatchlistES = (ids, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchWatchlistES(ids, size, unauthorizedEventIds));
  };
};

export const BIDDING_ON_REQUEST = 'BIDDING_ON_REQUEST';
export const BIDDING_ON_SUCCESS = 'BIDDING_ON_SUCCESS';
export const BIDDING_ON_FAILURE = 'BIDDING_ON_FAILURE';

const fetchBiddingOn = (ids = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [BIDDING_ON_REQUEST, BIDDING_ON_SUCCESS, BIDDING_ON_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ terms: { id: uniq(ids) } }],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
            should: [
              {
                // use this to maintain the order of ids
                query_string: {
                  default_field: 'id',
                  query: uniq(ids)
                    .reverse()
                    .map((id, index) => `${id}^${index + 1}`)
                    .join(' OR '),
                },
              },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadBiddingOn = (ids, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchBiddingOn(ids, size, unauthorizedEventIds));
  };
};

export const RECENTLY_VIEWED_REQUEST = 'RECENTLY_VIEWED_REQUEST';
export const RECENTLY_VIEWED_SUCCESS = 'RECENTLY_VIEWED_SUCCESS';
export const RECENTLY_VIEWED_FAILURE = 'RECENTLY_VIEWED_FAILURE';

const fetchRecentlyViewed = (ids = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        RECENTLY_VIEWED_REQUEST,
        RECENTLY_VIEWED_SUCCESS,
        RECENTLY_VIEWED_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ terms: { id: uniq(ids) } }],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
            should: [
              {
                // use this to maintain the order of ids
                query_string: {
                  default_field: 'id',
                  query: uniq(ids)
                    .reverse()
                    .map((id, index) => `${id}^${index + 1}`)
                    .join(' OR '),
                },
              },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRecentlyViewed = (ids, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRecentlyViewed(ids, size, unauthorizedEventIds));
  };
};

export const SELLING_NOW_REQUEST = 'SELLING_NOW_REQUEST';
export const SELLING_NOW_SUCCESS = 'SELLING_NOW_SUCCESS';
export const SELLING_NOW_FAILURE = 'SELLING_NOW_FAILURE';

const fetchSellingNow = (size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [SELLING_NOW_REQUEST, SELLING_NOW_SUCCESS, SELLING_NOW_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: [1] } },
              { term: { isMPSellingLive: true } },
              { range: { reservePrice: { gt: 0 } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        sort: [{ endDate: 'asc' }],
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadSellingNow = (size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchSellingNow(size, unauthorizedEventIds));
  };
};

export const BUY_NOW_REQUEST = 'BUY_NOW_REQUEST';
export const BUY_NOW_SUCCESS = 'BUY_NOW_SUCCESS';
export const BUY_NOW_FAILURE = 'BUY_NOW_FAILURE';

const fetchBuyNow = (size = 10, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [BUY_NOW_REQUEST, BUY_NOW_SUCCESS, BUY_NOW_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: [1] } },
              { range: { outrightPrice: { gt: 0 } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadBuyNow = (size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchBuyNow(size, unauthorizedEventIds));
  };
};

export const ALL_ACTIVITY_ES_REQUEST = 'ALL_ACTIVITY_ES_REQUEST';
export const ALL_ACTIVITY_ES_SUCCESS = 'ALL_ACTIVITY_ES_SUCCESS';
export const ALL_ACTIVITY_ES_FAILURE = 'ALL_ACTIVITY_ES_FAILURE';

const fetchAllActivityES = (ids = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        ALL_ACTIVITY_ES_REQUEST,
        ALL_ACTIVITY_ES_SUCCESS,
        ALL_ACTIVITY_ES_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: { terms: { id: uniq(ids) } },
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
            should: [
              {
                // use this to maintain the order of ids
                query_string: {
                  default_field: 'id',
                  query: uniq(ids)
                    .reverse()
                    .map((id, index) => `${id}^${index + 1}`)
                    .join(' OR '),
                },
              },
            ],
          },
        },
        size: size || 10000,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadAllActivityES = (ids, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchAllActivityES(ids, size, unauthorizedEventIds));
  };
};

export const NO_SALES_FROM_EVENTS_ES_REQUEST =
  'NO_SALES_FROM_EVENTS_ES_REQUEST';
export const NO_SALES_FROM_EVENTS_ES_SUCCESS =
  'NO_SALES_FROM_EVENTS_ES_SUCCESS';
export const NO_SALES_FROM_EVENTS_ES_FAILURE =
  'NO_SALES_FROM_EVENTS_ES_FAILURE';

const fetchNoSalesFromEventsES = (
  eventIds = [],
  size,
  unauthorizedEventIds = []
) => {
  return {
    [FETCH_API]: {
      types: [
        NO_SALES_FROM_EVENTS_ES_REQUEST,
        NO_SALES_FROM_EVENTS_ES_SUCCESS,
        NO_SALES_FROM_EVENTS_ES_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { eventId: eventIds } },
              { terms: { listingStatusId: [5] } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        sort: [{ endDate: 'asc' }],
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadNoSalesFromEventsES = (
  eventIds,
  size,
  unauthorizedEventIds
) => {
  return dispatch => {
    return dispatch(
      fetchNoSalesFromEventsES(eventIds, size, unauthorizedEventIds)
    );
  };
};

export const RECENTLY_ADDED_REQUEST = 'RECENTLY_ADDED_REQUEST';
export const RECENTLY_ADDED_SUCCESS = 'RECENTLY_ADDED_SUCCESS';
export const RECENTLY_ADDED_FAILURE = 'RECENTLY_ADDED_FAILURE';

const fetchRecentlyAdded = (
  statusIds = [],
  size = 20,
  unauthorizedEventIds = []
) => {
  return {
    [FETCH_API]: {
      types: [
        RECENTLY_ADDED_REQUEST,
        RECENTLY_ADDED_SUCCESS,
        RECENTLY_ADDED_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { term: { hasImage: true } },
              { range: { dateCreated: { gte: 'now-24h', lt: 'now' } } },
            ],
            must_not: [
              { term: { eventFeatures: 41 } },
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        sort: [{ dateCreated: 'desc' }],
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRecentlyAdded = (statusIds, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRecentlyAdded(statusIds, size, unauthorizedEventIds));
  };
};

export const RUNNING_TODAY_REQUEST = 'RUNNING_TODAY_REQUEST';
export const RUNNING_TODAY_SUCCESS = 'RUNNING_TODAY_SUCCESS';
export const RUNNING_TODAY_FAILURE = 'RUNNING_TODAY_FAILURE';

const fetchRunningToday = (size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        RUNNING_TODAY_REQUEST,
        RUNNING_TODAY_SUCCESS,
        RUNNING_TODAY_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { range: { liveStartTime: { gte: 'now/d', lte: 'now/d' } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        sort: [{ liveStartTime: 'asc' }],
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRunningToday = (size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRunningToday(size, unauthorizedEventIds));
  };
};

export const MOST_RECENT_REQUEST = 'MOST_RECENT_REQUEST';
export const MOST_RECENT_SUCCESS = 'MOST_RECENT_SUCCESS';
export const MOST_RECENT_FAILURE = 'MOST_RECENT_FAILURE';

const fetchMostRecent = (statusIds = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [MOST_RECENT_REQUEST, MOST_RECENT_SUCCESS, MOST_RECENT_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { term: { hasImage: true } },
              { range: { dateCreated: { gte: 'now-24h', lt: 'now' } } },
            ],
            must_not: [
              { term: { eventFeatures: 41 } },
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        sort: [{ dateCreated: 'desc' }],
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadMostRecent = (statusIds, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchMostRecent(statusIds, size, unauthorizedEventIds));
  };
};

export const RUNNING_TODAY_CHILD_REQUEST = 'RUNNING_TODAY_CHILD_REQUEST';
export const RUNNING_TODAY_CHILD_SUCCESS = 'RUNNING_TODAY_CHILD_SUCCESS';
export const RUNNING_TODAY_CHILD_FAILURE = 'RUNNING_TODAY_CHILD_FAILURE';

const fetchRunningTodayChild = (mpId, size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [
        RUNNING_TODAY_CHILD_REQUEST,
        RUNNING_TODAY_CHILD_SUCCESS,
        RUNNING_TODAY_CHILD_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { range: { liveStartTime: { gte: 'now/d', lte: 'now/d' } } },
              { term: { marketplaceId: mpId } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRunningTodayChild = (mpId, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRunningTodayChild(mpId, size, unauthorizedEventIds));
  };
};

export const TRADE_ITEMS_REQUEST = 'TRADE_ITEMS_REQUEST';
export const TRADE_ITEMS_SUCCESS = 'TRADE_ITEMS_SUCCESS';
export const TRADE_ITEMS_FAILURE = 'TRADE_ITEMS_FAILURE';

const fetchTradeItems = (statusIds = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [TRADE_ITEMS_REQUEST, TRADE_ITEMS_SUCCESS, TRADE_ITEMS_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { term: { eventFeatures: '42' } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadTradeItems = (statusIds, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchTradeItems(statusIds, size, unauthorizedEventIds));
  };
};

export const RETAIL_ITEMS_REQUEST = 'RETAIL_ITEMS_REQUEST';
export const RETAIL_ITEMS_SUCCESS = 'RETAIL_ITEMS_SUCCESS';
export const RETAIL_ITEMS_FAILURE = 'RETAIL_ITEMS_FAILURE';

const fetchRetailItems = (statusIds = [], size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [RETAIL_ITEMS_REQUEST, RETAIL_ITEMS_SUCCESS, RETAIL_ITEMS_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { listingStatusId: statusIds } },
              { term: { eventFeatures: '41' } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRetailItems = (statusIds, size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchRetailItems(statusIds, size, unauthorizedEventIds));
  };
};

export const ES_AGGREGATIONS_REQUEST = 'ES_AGGREGATIONS_REQUEST';
export const ES_AGGREGATIONS_SUCCESS = 'ES_AGGREGATIONS_SUCCESS';
export const ES_AGGREGATIONS_FAILURE = 'ES_AGGREGATIONS_FAILURE';

const fetchAggregationsES = (url = getConfig('searchRoot'), body = {}) => {
  return {
    [FETCH_API]: {
      types: [
        ES_AGGREGATIONS_REQUEST,
        ES_AGGREGATIONS_SUCCESS,
        ES_AGGREGATIONS_FAILURE,
      ],
      endpoint: `${url}_search?size=0`,
      method: 'POST',
      body: JSON.stringify(body),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadAggregationsES = (url, body) => {
  return dispatch => {
    return dispatch(fetchAggregationsES(url, body));
  };
};

export const MARKETPLACE_RECENTLY_ADDED_REQUEST =
  'MARKETPLACE_RECENTLY_ADDED_REQUEST';
export const MARKETPLACE_RECENTLY_ADDED_SUCCESS =
  'MARKETPLACE_RECENTLY_ADDED_SUCCESS';
export const MARKETPLACE_RECENTLY_ADDED_FAILURE =
  'MARKETPLACE_RECENTLY_ADDED_FAILURE';

const fetchMarketplaceRecentlyAdded = (
  marketplaceId,
  statusIds = [],
  size,
  unauthorizedEventIds = []
) => {
  return {
    [FETCH_API]: {
      types: [
        MARKETPLACE_RECENTLY_ADDED_REQUEST,
        MARKETPLACE_RECENTLY_ADDED_SUCCESS,
        MARKETPLACE_RECENTLY_ADDED_FAILURE,
      ],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { term: { marketplaceId: marketplaceId } },
              { terms: { listingStatusId: statusIds } },
              { term: { hasImage: true } },
              { range: { dateCreated: { gt: 'now-2d' } } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        sort: [{ dateCreated: 'desc' }],
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadMarketplaceRecentlyAdded = (
  marketplaceId,
  statusIds,
  size,
  unauthorizedEventIds
) => {
  return dispatch => {
    return dispatch(
      fetchMarketplaceRecentlyAdded(
        marketplaceId,
        statusIds,
        size,
        unauthorizedEventIds
      )
    );
  };
};

export const RUNLIST_ES_REQUEST = 'RUNLIST_ES_REQUEST';
export const RUNLIST_ES_SUCCESS = 'RUNLIST_ES_SUCCESS';
export const RUNLIST_ES_FAILURE = 'RUNLIST_ES_FAILURE';

const fetchRunListES = (
  statusIds,
  size,
  unauthorizedEventIds = [],
  eventId
) => {
  return {
    [FETCH_API]: {
      types: [RUNLIST_ES_REQUEST, RUNLIST_ES_SUCCESS, RUNLIST_ES_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { eventId: [eventId] } },
              { terms: { listingStatusId: statusIds } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        sort: [{ dateCreated: 'desc' }],
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRunListES = (
  statusIds,
  size,
  unauthorizedEventIds,
  eventId
) => {
  return dispatch => {
    return dispatch(
      fetchRunListES(statusIds, size, unauthorizedEventIds, eventId)
    );
  };
};

export const RESET_RUNLIST_ES = 'RESET_RUNLIST_ES';

export const resetRunlistES = () => {
  return { type: RESET_RUNLIST_ES };
};

export const RUNLIST_COUNT_REQUEST = 'RUNLIST_COUNT_REQUEST';
export const RUNLIST_COUNT_SUCCESS = 'RUNLIST_COUNT_SUCCESS';
export const RUNLIST_COUNT_FAILURE = 'RUNLIST_COUNT_FAILURE';

const fetchRunListCount = (
  statusIds = [],
  unauthorizedEventIds = [],
  eventId
) => {
  return {
    [FETCH_API]: {
      types: [
        RUNLIST_COUNT_REQUEST,
        RUNLIST_COUNT_SUCCESS,
        RUNLIST_COUNT_FAILURE,
      ],
      endpoint: '_count',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { eventId: [eventId] } },
              { terms: { listingStatusId: statusIds } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadRunListCount = (statusIds, unauthorizedEventIds, eventId) => {
  return dispatch => {
    return dispatch(
      fetchRunListCount(statusIds, unauthorizedEventIds, eventId)
    );
  };
};

export const FEATURED_ES_REQUEST = 'FEATURED_ES_REQUEST';
export const FEATURED_ES_SUCCESS = 'FEATURED_ES_SUCCESS';
export const FEATURED_ES_FAILURE = 'FEATURED_ES_FAILURE';

const fetchFeaturedES = (size, unauthorizedEventIds = []) => {
  return {
    [FETCH_API]: {
      types: [FEATURED_ES_REQUEST, FEATURED_ES_SUCCESS, FEATURED_ES_FAILURE],
      endpoint: '_search',
      method: 'POST',
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              { terms: { featured: [true] } },
              { terms: { listingStatusId: [1, 3] } },
            ],
            must_not: [
              { terms: { eventId: unauthorizedEventIds } },
              { range: { parentListingId: { gt: 0 } } },
            ],
          },
        },
        size: size || 10000,
        sort: [{ dateCreated: 'desc' }],
        track_total_hits: true,
      }),
      search: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadFeaturedES = (size, unauthorizedEventIds) => {
  return dispatch => {
    return dispatch(fetchFeaturedES(size, unauthorizedEventIds));
  };
};

export const IMPERSONATE_USER_LIST_REQUEST = 'IMPERSONATE_USER_LIST_REQUEST';
export const IMPERSONATE_USER_LIST_SUCCESS = 'IMPERSONATE_USER_LIST_SUCCESS';
export const IMPERSONATE_USER_LIST_FAILURE = 'IMPERSONATE_USER_LIST_FAILURE';

const fetchImpersonateList = mpId => {
  return {
    [FETCH_API]: {
      types: [
        IMPERSONATE_USER_LIST_REQUEST,
        IMPERSONATE_USER_LIST_SUCCESS,
        IMPERSONATE_USER_LIST_FAILURE,
      ],
      endpoint: `list-users-for-impersonation?mpId=${mpId}&apiKey=`,
      method: 'GET',
    },
  };
};

export const loadImpersonateList = mpId => {
  return dispatch => {
    return dispatch(fetchImpersonateList(mpId));
  };
};

export const IMPERSONATE_USER_REQUEST = 'IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_USER_FAILURE = 'IMPERSONATE_USER_FAILURE';

const fetchImpersonateUser = (mpId, userId) => {
  return {
    [FETCH_API]: {
      types: [
        IMPERSONATE_USER_REQUEST,
        IMPERSONATE_USER_SUCCESS,
        IMPERSONATE_USER_FAILURE,
      ],
      endpoint:
        `impersonate-user?mpId=${mpId}` + `&apiKey=` + `&userId=${userId}`,
      method: 'GET',
    },
  };
};

export const loadImpersonateUser = (mpId, userId) => {
  return dispatch => {
    return dispatch(fetchImpersonateUser(mpId, userId));
  };
};

export const ONLINE_CONNECTED = 'ONLINE_CONNECTED';
export const setOnlineConnected = connected => {
  return {
    type: ONLINE_CONNECTED,
    response: connected,
  };
};

export const REAL_TIME_CONNECTED = 'REAL_TIME_CONNECTED';
export const setRealTimeConnected = realTimeConnected => {
  return {
    type: REAL_TIME_CONNECTED,
    response: realTimeConnected,
  };
};

export const SET_CUSTOMER_TERMS_LINK = 'SET_CUSTOMER_TERMS_LINK';
export const setCustomerTermsLink = termsLink => {
  return {
    type: SET_CUSTOMER_TERMS_LINK,
    response: termsLink,
  };
};

export const SET_CUSTOMER_PLATFORM_LINK = 'SET_CUSTOMER_PLATFORM_LINK';
export const setCustomerPlatformLink = platformLink => {
  return {
    type: SET_CUSTOMER_PLATFORM_LINK,
    response: platformLink,
  };
};

export const GET_SHIPPING_QUOTE_REQUEST = 'GET_SHIPPING_QUOTE_REQUEST';
export const GET_SHIPPING_QUOTE_SUCCESS = 'GET_SHIPPING_QUOTE_SUCCESS';
export const GET_SHIPPING_QUOTE_FAILURE = 'GET_SHIPPING_QUOTE_FAILURE';

export const fetchGetShippingQuote = (
  mpId,
  itemId,
  originZip,
  destinationZip,
  isInoperable = false,
  isEnclosed = false
) => {
  return {
    [FETCH_API]: {
      types: [
        GET_SHIPPING_QUOTE_REQUEST,
        GET_SHIPPING_QUOTE_SUCCESS,
        GET_SHIPPING_QUOTE_FAILURE,
      ],
      endpoint:
        `shipping/${itemId}/rate` +
        `?apiKey=` +
        `&mpId=${mpId}` +
        `&originZip=${originZip}` +
        `&destinationZip=${destinationZip}` +
        `&isInoperable=${isInoperable}` +
        `&isEnclosed=${isEnclosed}`,
    },
  };
};

export const loadGetShippingQuote = (
  mpId,
  itemId,
  originZip,
  destinationZip,
  isInoperable,
  isEnclosed
) => {
  return dispatch =>
    dispatch(
      fetchGetShippingQuote(
        mpId,
        itemId,
        originZip,
        destinationZip,
        isInoperable,
        isEnclosed
      )
    );
};

export const ACERTUS_FORM_REQUEST = "ACERTUS_FORM_REQUEST";
export const ACERTUS_FORM_SUCCESS = "ACERTUS_FORM_SUCCESS";
export const ACERTUS_FORM_FAILURE = "ACERTUS_FORM_FAILURE";

export const fetchAcertusBookShipping = (
  mpId,
  itemId,
  requestBody={} // fields -> src/models/AcertusRequestBody.js
) => {
  return {
    [FETCH_API]: {
      types: [ACERTUS_FORM_REQUEST, ACERTUS_FORM_SUCCESS, ACERTUS_FORM_FAILURE],
      body: JSON.stringify(requestBody),
      endpoint: 
        `shipping/${itemId}/contact?apiKey=`,
      method: 
        'POST',
      headers: {
        Accept:         '*/*',
        'Content-Type': 'application/json'
      }
    },

  }
}

export const loadAcertusBookShipping = (
  mpId,
  itemId,
  requestBody={} // fields -> src/models/AcertusRequestBody.js
) => {
  return dispatch =>
    dispatch(
      fetchAcertusBookShipping(
        mpId,
        itemId,
        requestBody
      )
    );
}

export const RESET_ACERTUS_FORM = "RESET_ACERTUS_FORM";
export const resetAcertusSubmission = () => {
  return { type: RESET_ACERTUS_FORM };
}

export const RESET_SHIPPING_QUOTE = 'RESET_SHIPPING_QUOTE';
export const resetShippingQuote = () => {
  return { type: RESET_SHIPPING_QUOTE };
};

export * from './arbitration';
export * from './marketplace';
export * from './core/AccountActions';
export * from './core/AutoGradeActions';
export * from './core/ConditionActions';
export * from './core/ConfigActions';
export * from './core/EventActions';
export * from './core/ImageActions';
export * from './core/InventoryActions';
export * from './core/LotNumberActions';
export * from './core/PostActions';
export * from './core/SectionActions';
export * from './core/SideBarActions';
export * from './core/VinActions';
export * from './core/ValidationActions';
